import React from "react";
import { TitlePrimary } from "../../../Utility/Utility";
import Timestamp from "react-timestamp";

const SiteStats = ({ stats }) => {

  return (
    <>
      <div className="border-[5px] border-white rounded-2xl_next bg-three shadow-one p-4 md:p-7 pb-5">
        <div className="text-center mb-5 md:mb-7">
            <TitlePrimary>global STATS</TitlePrimary>
        </div>
        <div className="table-custom-scroller w-full">
          {stats && 
            <table className="table-custom w-full font-oxanium">
              <tbody>
                <tr>
                    <td className="max-w-[140px]">Launched</td>
                    <td className="text-right"><Timestamp date={1703598040} relative /></td>
                </tr>
                <tr>
                    <td className="max-w-[140px]">Total Users</td>
                    <td className="text-right">{stats.totalUsers}</td>
                </tr>
                <tr>
                    <td className="max-w-[140px]">Total Contracts</td>
                    <td className="text-right">{stats.totalContracts}</td>
                </tr>
                <tr>
                    <td className="max-w-[140px]">Expired Contracts</td>
                    <td className="text-right">{stats.expiredContracts}</td>
                </tr>
                <tr>
                    <td className="max-w-[140px]">Rewards Claimed</td>
                    <td className="text-right">${stats.totalClaimed}</td>
                </tr>
                <tr>
                    <td className="max-w-[140px]">Fast Start Earned</td>
                    <td className="text-right">${stats.totalFaststart}</td>
                </tr>
              </tbody>
            </table>
          }
        </div>
      </div>
    </>
  );
};
export default SiteStats;