import React from "react";
import { TitlePrimary } from "../../../Utility/Utility";
import { useHelpers } from "../../../Hooks/helpers";

const UserStats = ({ stats }) => {
  
  const helper = useHelpers();

  return (
    <>
      <div className="border-[5px] border-white rounded-2xl_next bg-three shadow-one p-4 md:p-7 pb-5">
        <div className="text-center mb-5 md:mb-7">
            <TitlePrimary>MY STATS</TitlePrimary>
        </div>
        <div className="table-custom-scroller w-full">
          {stats && 
            <table className="table-custom w-full font-oxanium">
              <tbody>
                <tr>
                    <td className="max-w-[140px]">Contracts Bought</td>
                    <td className="text-right">{stats.contractsBought}</td>
                </tr>
                <tr>
                    <td className="max-w-[140px]">Active Contracts</td>
                    <td className="text-right">{stats.activeContracts}</td>
                </tr>
                <tr>
                    <td className="max-w-[140px]">Expired/Refunded Contracts</td>
                    <td className="text-right">{stats.contractsBought*1 - stats.activeContracts*1}</td>
                </tr>
                <tr>
                    <td className="max-w-[140px]">Daily Earnings</td>
                    <td className="text-right"><helper.AmountToCurrency amount={stats.dailyEarnings} /></td>
                </tr>
                <tr>
                    <td className="max-w-[140px]">Total Claimed</td>
                    <td className="text-right"><helper.AmountToCurrency amount={stats.totalClaimed} /></td>
                </tr>
                <tr>
                    <td className="max-w-[140px]">Fast Start Earned</td>
                    <td className="text-right"><helper.AmountToCurrency amount={stats.fastStartEarned} /></td>
                </tr>
                <tr>
                    <td className="max-w-[140px]">Missed Fast Start Earnings</td>
                    <td className="text-right"><helper.AmountToCurrency amount={stats.missedFastStartEarnings} /></td>
                </tr>
                <tr>
                    <td className="max-w-[140px]">Total Referrals</td>
                    <td className="text-right">{stats.totalReferrals}</td>
                </tr>
                <tr>
                    <td className="max-w-[140px]">Referred Today</td>
                    <td className="text-right">{stats.referredToday}</td>
                </tr>
              </tbody>
            </table>
          }
        </div>
      </div>
    </>
  );
};
export default UserStats;
