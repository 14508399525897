import { Link } from "react-router-dom";
import logo from "../../../Assets/logo.png";
import ConnectedWallet from "./ConnectedWallet";
import MenuDash from "./MenuDash";

const HeaderDash = () => {
  return (
    <header className="bg-transparent">
      <div className="flex items-center justify-start md:justify-center z-[1] relative h-[56px]">
        <Link>
          <img
            src={logo}
            alt="logo"
            className="max-w-[120px] md:max-h-[100px] xl:max-h-[124px] lg:max-w-[200px]"
          />
        </Link>
        <div className="absolute right-0 top-1/2 -translate-y-1/2">
          <ConnectedWallet />
        </div>

        <div className="fixed sm:absolute w-full left-0 right-0 bottom-0 sm:bottom-auto sm:top-[70px] lg:top-[80px] z-[5] bg-[rgba(3,0,12,0.30)] py-3 sm:border-[3px] sm:border-secondary sm:rounded-2xl_next sm:mx-auto sm:px-4 sm:py-3 sm:w-[380px]">
          <MenuDash />
        </div>
      </div>
    </header>
  );
};
export default HeaderDash;
