import React from "react";
import bgDashboard from "../Assets/bg-dashboard.webp";
import AppRouter from "./AppRouter";
import HeaderDash from "../Components/dash/layout/HeaderDash";
import appConfig from "../Utility/Config/config";
import Footer from "../Components/dash/layout/Footer";
import LearnMore from "../Components/dash/layout/LearnMore";

const Dashboardlayout = () => {

  return (

    <div className="bg-[#0B002B] min-h-screen relative font-khand px-4 pb-4 md:p-6 pt-4 overflow-hidden">
      <div
        className="w-full h-full absolute top-0 start-0 bottom-0 end-0 bg-cover bg-center z-0"
        style={{ backgroundImage: `url(${bgDashboard})`, backgroundPosition: 'center center', backgroundSize: 'cover', backgroundAttachment: 'fixed'}}
      >
        <span className="w-full h-full bg-one block absolute top-0 left-0 right-0 bottom-0"></span>
      </div>
      <div className="mb-[30px] sm:mb-[130px] md:mb-[120px]">
        <HeaderDash />
      </div>
      <main className="relative mb-20 sm:mb-0 ">
        <AppRouter />
        <LearnMore />
        <Footer />
      </main>
      
    </div>
  );
};

export default Dashboardlayout;
