import { Button, Dialog, DialogBody, DialogFooter, DialogHeader } from '@material-tailwind/react';
import { useState } from 'react';

export const useAppDialog = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState(false);

    const show = ({ header, description, buttons, backdropDismiss = false }) => {
        setData({
            header: header,
            description: description,
            buttons: buttons,
            backdropDismiss: backdropDismiss
        });
        setIsOpen(true);
    }

    return {
        isOpen: isOpen,  //state
        data: data,
        open: show, //open
        close: () => setIsOpen(false) //close
    };
}

export const AppDialog = ({ isOpen, dismiss, data }) => {

    return (
        <Dialog size='sm' open={isOpen} handler={data.backdropDismiss ? dismiss : null}>
            <DialogBody className='p-7'>
                <div className="text-lg font-bold leading-6 text-gray-900">
                    {data.header}
                </div>
                <div className="mt-2">
                    <p className="text-sm font-normal text-gray-600">{data.description}</p>
                </div>
                <div className='mt-4 space-x-2'>
                    {data.buttons && data.buttons.length > 0 ?
                        <>
                            {data.buttons.map((e, k) => {
                                return (
                                    <Button
                                        key={k}
                                        type="button"
                                        variant={e.variant ? e.variant : 'gradient'}
                                        color={e.role === 'cancel' ? 'red' : 'lime'}
                                        onClick={e.role === 'cancel' ? dismiss : e.action}
                                    >
                                        {e.text}
                                    </Button>
                                );
                            })}
                        </>
                    :
                        <Button
                            type="button"
                            color='red'  
                            variant='outlined'
                            onClick={dismiss}
                        >
                            Close
                        </Button>
                    }
                </div>
            </DialogBody>
        </Dialog>
    )
}