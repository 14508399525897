export const useDeviceData = () => {

    const getDeviceId = () => {

        let deviceDeviceId;
		//Get localstorage deviceid for reference purpose
		if(localStorage.getItem('deviceReference') != null) {
			deviceDeviceId = localStorage.getItem('deviceReference');
		} else {
			var result           = '';
			var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			var charactersLength = characters.length;

			for ( var i = 0; i < 16; i++ ) {
				result += characters.charAt(Math.floor(Math.random() * charactersLength));
			}
			deviceDeviceId = result;
			localStorage.setItem('deviceReference', deviceDeviceId);
		}
		return deviceDeviceId;
	}

	const getUserAuthToken = () => {
		return window.localStorage.getItem('userauthtoken') !== null ? window.localStorage.getItem('userauthtoken') : ''
	}

    return { getDeviceId, getUserAuthToken };

};