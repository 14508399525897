import React, { useEffect, useState } from "react";
import { useUserAuth } from '../../../Contexts/authContext';
import { useWalletContext } from "../../../Contexts/walletContext";
import { useCallAPI } from "../../../Hooks/callAPI";
import { useHelpers } from "../../../Hooks/helpers";
import { LoadingData, TitlePrimary } from "../../../Utility/Utility";
import { Button } from "@material-tailwind/react";

const DeclaredContests = () => {

    const auth = useUserAuth();
    const callAPI = useCallAPI();
    const walletContext = useWalletContext();
    const helper = useHelpers();

    const [contests, setContests] = useState(false);
    
    //Get user balance
    useEffect(() => {
        if(auth.appBootstrapped === true && walletContext.walletConnected === true) {
            getResults();
        }
    }, [auth, walletContext.walletConnected]);
        
    const getResults = async () => {        
        const res = await callAPI("getDeclaredContests");   
        if(res.status && res.data) {
            setContests(res.data);
        }
    }

    return (
        <>
            {contests &&
                <div>
                    <div className="text-center mb-7 md:mb-9 max-w-[800px] mx-auto">
                        <div className="border-[5px] border-white rounded-2xl_next bg-four shadow-one p-4 md:p-7 pb-5 mt-10">
                            <div className="text-left mb-5 md:mb-7">
                                <TitlePrimary>PREVIOUS CONTESTS</TitlePrimary>
                            </div>
                            <div className="table-custom-scroller w-full">
                                {contests && 
                                    <table className="table-custom w-full font-oxanium">
                                        <thead>
                                            <tr>
                                                <th className="">#</th>
                                                <th className="">Started</th>
                                                <th className="">Ended</th>
                                                <th className="">Total Pool</th>
                                                <th className=""></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {contests.map((e, k) => {
                                                return (
                                                    <tr key={k}>
                                                        <td className="text-left">#{e.id}</td>
                                                        <td className="text-left">{helper.ProcessDate(e.start)}</td>
                                                        <td className="text-left">{helper.ProcessDate(e.end)}</td>
                                                        <td className="text-center"><helper.AmountToCurrency amount={(e.pool*1 + e.jumpStart*1) + (e.pool*1 + e.jumpStart*1)*0.02} decimals={0} /></td>
                                                        <td className="text-right"><a href={'/contestWinners/' + e.id} target="blank"><Button color="indigo" variant="gradient" size="sm">Show Winners</Button></a></td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            {contests === false &&
                <LoadingData />
            }
            
        </>
    );
};

export default DeclaredContests;
