import React from "react";
import {
  Navigate,
  Route,
  Routes
} from "react-router-dom";

//Import pages
import Activities from "../Pages/dash/Activities";
import Dashboard from "../Pages/dash/Dashboard";
import Contracts from "../Pages/dash/Contracts";
import Payments from "../Pages/dash/Payments";
import Referrals from "../Pages/dash/Referrals";
import TelegramAlerts from "../Pages/dash/TelegramAlerts";
import Connect from "../Pages/auth/Connect";
import Logout from "../Pages/auth/Logout";
import BonusPool from "../Pages/dash/BonusPool";
import ContestWinners from "../Components/dash/bonuspool/ContestWinners";
import ArbigrateLogs from "../Pages/dash/ArbitrageLogs";

export default function AppRouter() {
  return (
    <Routes>
        {/* Dashboard Routes */}
        <Route exact path="/" element={<Navigate to="/dashboard" />}></Route>

        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route path="/contracts" element={<Contracts />}></Route>
        <Route path="/referrals" element={<Referrals />}></Route>
        <Route path="/activities" element={<Activities />}></Route>
        <Route path="/payments" element={<Payments />}></Route>
        <Route path="/tgAlerts" element={<TelegramAlerts />}></Route>
        <Route path="/bonusPool" element={<BonusPool />}></Route>
        <Route path="/contestWinners/:id" element={<ContestWinners />}></Route>
        <Route path="/contributions" element={<ArbigrateLogs />}></Route>

        {/* Authentication Routes */}
        <Route path="/connect" element={<Connect />}></Route>
        <Route path="/logout" element={<Logout />}></Route>

        {/* //Referral LInk */}
        <Route path="/connect/:ref" element={<Connect />}></Route>
    </Routes>
  );
}