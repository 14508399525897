import React, { useEffect, useState } from "react";
import { useUserAuth } from '../../Contexts/authContext';
import { useWalletContext } from "../../Contexts/walletContext";
import { useCallAPI } from "../../Hooks/callAPI";
import { EmptyRecords, LoadingData, TitlePrimary } from "../../Utility/Utility";
import ContractItem from "../../Components/dash/packs/ContractItem";
import { useHelpers } from "../../Hooks/helpers";
import Timestamp from "react-timestamp";
import toast from "react-hot-toast";
import Countdown from "react-countdown";
import DeclaredContests from "../../Components/dash/bonuspool/DeclaredContest";

const BonusPool = () => {

    const auth = useUserAuth();
    const callAPI = useCallAPI();
    const walletContext = useWalletContext();
    const helper = useHelpers();

    const [contestData, setContestData] = useState(false);
    const [breakdown, setBreakdown] = useState([]);
    
    //Get user balance
    useEffect(() => {
        if(auth.appBootstrapped === true && walletContext.walletConnected === true) {
            getResults();
        }
    }, [auth, walletContext.walletConnected]);
        
    const getResults = async () => {        
        const res = await callAPI("getOngoingContestData");   
        if(res.status && res.data && res.data.error === undefined) {
            setContestData(res.data);
        } else {
            toast.error(res.data.error);
        }

        //Set breakdown array
        setBreakdown(auth?.systemSettings?.CONTEST_BREAKDOWN.split(','));
    }

    return (
        <>
            {contestData &&
                <div>
                    <div className="text-center pt-9 max-w-[800px] mx-auto">
                        <TitlePrimary>REFERRAL BONUS CONTEST</TitlePrimary>
                        <div className="text-3xl font-oxanium text-white mt-2">Anyone can participate to WIN Big!</div>
                        <div className="flex items-center align-middle justify-between font-oxanium text-white space-x-10 mt-10 bg-six border-4 border-white rounded-2xl p-5">
                            <div className="text-left">
                                <p className=" text-5xl font-bold text-primary txt_shadow_one"><helper.AmountToCurrency amount={contestData.totalPool} decimals={0}/></p>
                                <p className="text-md font-normal txt_shadow_one">Prize Pool</p>
                            </div>
                            <div className="text-right">
                                <p className=" text-2xl font-bold text-primary txt_shadow_one"><Countdown date={contestData.contestData.end*1000} renderer={({ days, hours, minutes, seconds, completed }) => { return <span>{days}d : {hours}h : {minutes}m</span>; }} /></p>
                                <p className="text-md font-normal txt_shadow_one">Results declare in</p>
                            </div>
                        </div>
                        <div className="border-[5px] border-white rounded-2xl_next bg-three shadow-one p-4 md:p-7 pb-5 mt-10">
                            <div className="text-left mb-5 md:mb-7">
                                <TitlePrimary>SEASON rankings</TitlePrimary>
                            </div>
                            <div className="table-custom-scroller w-full">
                                {contestData.rankings && 
                                    <table className="table-custom w-full font-oxanium">
                                        <thead>
                                            <tr>
                                                <th className="">Rank</th>
                                                <th className="">Prize to Win</th>
                                                <th className="">Referrals</th>
                                                <th className="">Address</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {contestData.rankings.map((e, k) => {
                                                return (
                                                    <tr key={k}>
                                                        <td className="text-left">#{k+1}</td>
                                                        <td className="text-center">${contestData.totalPool*breakdown[k]/10000}</td>
                                                        <td className="text-center">{e.referrals}</td>
                                                        <td className="text-left">{e.address}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                }
                            </div>
                            <p className="text-xs mt-4 text-right text-gray-400 ">A 10% fee is deducted from the winning amount as bonus pool processing fee.</p>
                        </div>
                        <DeclaredContests /> 
                    </div>
                </div>
            }
            {contestData === false &&
                <LoadingData />
            }
        </>
    );
};

export default BonusPool;
