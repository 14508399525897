import React from "react";
import Countdown from "react-countdown";

const ContractEndTime = ({ time }) => {
  return (
    <div className="flex items-center justify-end text-lg">
      <p className="text-white txt_shadow_one font-oxanium font-bold text-lg leading-tight">
        Expires in
        <strong className="block text-[#40C5E6] txt_shadow_one text-2xl leading-[.8] mt-1">
          <Countdown date={time*1000} renderer={({ days, hours, minutes, seconds }) => { return <span>{days*1 > 0 && days + ' days'}{days*1 == 0 && hours*1 > 0 && hours + ' hours'}{days*1 == 0 && hours*1 == 0 && minutes*1 > 0 && minutes + ' minutes'}{days*1 == 0 && hours*1 == 0 && minutes*1 == 0 && seconds > 0 && seconds + 'seconds'}</span> }} />
        </strong>
      </p>
    </div>
  );
};

export default ContractEndTime;
