import { useState } from 'react';
import { BounceLoader, FadeLoader, HashLoader, PuffLoader, RingLoader, RotateLoader } from 'react-spinners';
import { useHelpers } from './helpers';

export const usePendingModal = () => {

    const [show, setShow] = useState(false);
    const [data, setData] = useState('');

    const showModal = (data) => {
        setShow(true);
        setData(data);
    }

    const hideModal = () => {
        setShow(false);
    }

    return [show, data, showModal, hideModal];
}

export const PendingModal = ({ data }) => {

    return (
        <PuffLoader
            color='#F8892B'
            loading={true}
            size={50}
            aria-label="Loading"
            data-testid="loader"
            className='mx-auto'
        />
    )
}