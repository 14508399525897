import React, { useEffect, useState } from "react";
import { useUserAuth } from '../../Contexts/authContext';
import { useWalletContext } from "../../Contexts/walletContext";
import { useCallAPI } from "../../Hooks/callAPI";
import { EmptyRecords, LoadingData, TitlePrimary } from "../../Utility/Utility";
import ContractItem from "../../Components/dash/packs/ContractItem";

const Packs = () => {

    const auth = useUserAuth();
    const callAPI = useCallAPI();
    const walletContext = useWalletContext();

    const [packs, setPacks] = useState(false);
    
    //Get user balance
    useEffect(() => {
        if(auth.appBootstrapped === true && walletContext.walletConnected === true) {
            getResults();
        }
    }, [auth, walletContext.walletConnected]);
        
    const getResults = async () => {        
        const res = await callAPI("getPacks");     
        if(res.status && res.data) {
            setPacks(res.data);
        } else {
            setPacks([]);
        }
    }

    return (
        <>
            {packs && packs.length > 0 &&
                <div>
                    <div className="text-center mb-7 md:mb-9 pt-9">
                        <TitlePrimary>MY CONTRACTS</TitlePrimary>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-7">
                        {packs && packs.length > 0 && packs.map((e, i) => {
                            return (
                                <ContractItem key={i} pack={e} showKey={i+1} />
                            )
                        })}
                    </div>
                </div>
            }
            {packs && packs.length === 0 &&
                <EmptyRecords title='No Contracts Found' description='Purchased Contracts will be shown here.' />
            }
            {packs === false &&
                <LoadingData />
            }
        </>
    );
};

export default Packs;
