export const TitlePrimary = ({ children }) => {
  return (
    <h2 className="text-white shadow-1 text-2xl xl:text-4xl font-orbitron font-bold tracking-[3.1px] uppercase">
      {children}
    </h2>
  );
};

export const TitlePrimaryTwo = ({ children }) => {
  return (
    <h2 className="text-primary shadow-1 text-2xl xl:text-4xl translate-y-1 font-longshotRegular font-normal tracking-[1.8px]">
      {children}
    </h2>
  );
};

export const TitleSecondary = ({ children }) => {
  return (
    <h2 className="text-primary uppercase text-2xl translate-y-1 font-longshotRegular font-normal tracking-[1.5px]">
      {children}
    </h2>
  );
};

export const Overlay = ({ overlay }) => {
  return (
    <div
      className={`${
        overlay ? "opacity-100 visible" : "opacity-0 invisible"
      } fixed w-screen h-screen top-0 left-0 right-0 bg-black/60 transition duration-300 z-40 `}
    ></div>
  );
};

export const BlkContainer = ({ children, className }) => {
  return <div className={`blk-container ${className}`}>{children}</div>;
};

export const LoadingData = ({ fullHeight = true }) => {
  return (
      <div className={`${fullHeight ? 'grid place-items-center py-[50px] sm:py-[100px]' : 'm-auto'}`}>
        <div >
          <PuffLoader
              color={'#111'}
              loading={true}
              size={50}
              aria-label="Loading"
              data-testid="loader"
          />
        </div>
      </div>
  )
}

import { PuffLoader, SyncLoader } from 'react-spinners';
import emptyImg from '../Assets/emptyImg.png';
export const EmptyRecords = ({ title, description, buttonHandler = false, fullHeight = false }) => {
  return (
    <div className={`grid place-items-center ${fullHeight ? 'h-screen' : 'py-[50px] sm:py-[100px]'}`}>
      <div className="max-w-[300px] text-center p-5 bg-[#ffffff] shadow-lg rounded-xl font-oxanium">
        <img className="w-[100px] sm:w-[150px] block mx-auto my-2" src={emptyImg} />
        <p className="text-blue text-2xl font-bold my-10 mb-3 mx-3 leading-5">{title}</p>
        <p className="text-gray-600 font-trunoRegular text-sm mx-5 leading-5 mt-1">{description}</p>
        {buttonHandler && <button className="bg-gradient-two px-5 py-2 rounded-[5px] font-bold text-sm mt-4" onClick={buttonHandler.onClick}>{buttonHandler.text}</button>}
      </div>
    </div>
  )
}


//Form Components
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Input } from "@material-tailwind/react";
import { AlertTriangle } from "react-feather";

export const FormBlock = ({ children, ...props }) => {
  return (
    <div {...props} className="bg-gradient-two rounded-lg_next border border-black p-5 space-y-5">
      <h4 className='text-white text-shadow-one font-trunoSemibold text-[18px] font-bold'>
        {props.heading}
      </h4>
      {children}
    </div>
  );
};

export const FormLabel = ({ children, ...props }) => {
  return (
    <div className="">
      <div className="font-ibm-plex-sans text-sm font-semibold text-gray-300"><label {...props}>{children}</label></div>
      <p className=" text-gray-300/50 text-sm leading-[14px]">{props.helper}</p>
    </div>
  );
};

export const FormInput = ({ children, ...props }) => {
  return (
    <>
      <Input color='lime' className="text-gray-400" size='lg' {...props} {...props.reg(props.name)} icon={(props.isdirty && (props.errors[props.name]?.message || props.formerror[props.name])) ? <AlertTriangle size={16} className="text-red-400" /> : null} error={(props.isdirty && (props.errors[props.name]?.message || props.formerror[props.name])) ? true : false} />
      {(props.isdirty && (props.errors[props.name]?.message || props.formerror[props.name])) ? 
        <>
          {props.errors[props.name]?.message && <p className="text-red-400 text-sm leading-4 mt-2 text-left">{props.errors[props.name]?.message}</p>}
          {props.formerror[props.name] && <p className="text-red-400 text-sm leading-4 mt-2 text-left">{props.formerror[props.name]}</p>}
        </>
        :
        <p className="flex justify-start text-gray-300/50 text-xs leading-4 mt-2"><InformationCircleIcon width={12} className="mr-1"/> {props.helper}</p>
      }
    </>
  );
};