import React, {
    createContext,
    useContext, useEffect, useState
} from "react";

const AppState = createContext(null);

// context consumer hook
const useAppState = () => {
    // get the context
    const context = useContext(AppState);

    // if `undefined`, throw an error
    if (context === undefined) {
        throw new Error("AppState was used outside of its Provider");
    }

    return context;
};

const AppStateProvider = ({ children }) => {

    //Declare state vars
    const [appInFocus, setAppInFocus] = useState(true);
    const [networkState, setNetworkState] = useState(navigator.onLine);

    useEffect(function() {

        //Create event for app state focus and blur
        window.addEventListener("visibilitychange", checkAppState);
        window.addEventListener("online", checkNetworkState);
        window.addEventListener("offline", checkNetworkState);

        return () => {
            window.addEventListener("visibilitychange", checkAppState);
            window.removeEventListener("online", checkNetworkState);
            window.removeEventListener("offline", checkNetworkState);
        };
    }, []);

    const checkAppState = () => {
        setAppInFocus(document.visibilityState === 'visible' ? true : false);
    }

    const checkNetworkState = () => {
        setNetworkState(navigator.onLine);
    }

    return (
        <AppState.Provider value={{ 
                appInFocus: appInFocus,
                networkState: networkState
            }}>
            {children}
        </AppState.Provider>
    );
};

export { AppStateProvider, useAppState };