import { Button, Chip } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useUserAuth } from '../../Contexts/authContext';
import { useWalletContext } from "../../Contexts/walletContext";
import { useCallAPI } from "../../Hooks/callAPI";
import { useHelpers } from "../../Hooks/helpers";
import { EmptyRecords, LoadingData, TitlePrimary } from "../../Utility/Utility";

const Payments = () => {

    const auth = useUserAuth();
    const helper = useHelpers();
    const callAPI = useCallAPI();
    const walletContext = useWalletContext();

    const [data, setData] = useState([]);
    const [dataLoadFull, setDataLoadFull] = useState(false);

    //Get user balance
    useEffect(() => {
        if(auth.appBootstrapped === true && walletContext.walletConnected === true) {
            getResults(0);
        }
    }, [auth, walletContext.walletConnected]);

    const getResults = async (offset) => {
        const res = await callAPI("getPayments" + "/" + offset);
        if(res.data !== false) {
          setData([...data, ...res.data]);
        } else {
            toast.success("All Rows Loaded");
            setDataLoadFull(true);
        }
    }

    return (
        <>
            <div className="border-[5px] border-white rounded-2xl_next bg-three shadow-one p-4 md:p-7 pb-5">
                <div className="text-center mb-5 md:mb-7">
                    <TitlePrimary>MY Payments</TitlePrimary>
                </div>
                {data && data.length > 0 &&
                    <>
                        <div className="table-custom-scroller">
                            <table className="table-custom w-full font-oxanium">
                                <thead>
                                    <tr>
                                        <th>From</th>
                                        <th>Contract #</th>
                                        <th>Amount</th>
                                        <th>Type</th>
                                        <th>Hash</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {data && data.length > 0 && data.map((e, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{e.from_id*1 !== 0 ? <>ID {e.from_id}</> : <>System</>}</td>
                                            <td>#{e.pack_id}</td>
                                            <td><helper.AmountToCurrency amount={e.amount} /></td>
                                            <td className="px-5 py-4 max-w-[140px]">{helper.capitalize(e.type)}</td>
                                            <td><Chip className="inline" color='black' value={helper.trimAddress({ address: e.hash, copy: true })} /></td>
                                            <td>{helper.ProcessDate(e.created)}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                            {!dataLoadFull && <Button color="teal" className="mt-4" onClick={() => getResults(data.length)}>Load More</Button>}
                        </div>
                    </>
                }
                {data && data.length === 0 &&
                    <EmptyRecords title='No Payments Found' description='Your payments will be listed here.' />
                }
                {data === false &&
                    <LoadingData />
                }
            </div>
            
        </>
    );
};

export default Payments;
