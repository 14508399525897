import axios from 'axios';
import toast from 'react-hot-toast';
import appConfig from '../Utility/Config/config';
import { useDeviceData } from './deviceData';

export const useCallAPI = () => {

    const device = useDeviceData();

    const getResults = async (callFn, data = {}) => {

        const headers = {
            'Content-Type': 'application/json',
            'deviceid': device.getDeviceId(),
            'userauthtoken': device.getUserAuthToken()
        }

        let response = {};
        await axios.post(appConfig.apiEndpoint + callFn, data, {
            headers: headers
        }).then(function(res) {
            response.data = res.data;
            response.status = true;
        }).catch(function(err) {
            console.log(err);
            response.data = err;
            response.status = false;
            toast.error('Server error. Try again later.');
        });
        return response;
    }

    return getResults;

};