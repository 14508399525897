import { Button } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ClaimRewards from "../../Components/dash/dashboard/ClaimRewards";
import CopyLink from "../../Components/dash/dashboard/CopyLink";
import PurchasePack from "../../Components/dash/dashboard/PurchasePack";
import SiteStats from "../../Components/dash/dashboard/SiteStats";
import UserStats from "../../Components/dash/dashboard/UserStats";
import { useUserAuth } from '../../Contexts/authContext';
import { useWalletContext } from "../../Contexts/walletContext";
import { useCallAPI } from "../../Hooks/callAPI";
import { useHelpers } from "../../Hooks/helpers";
import { useReadContract } from "../../Hooks/readContract";
import { LoadingData } from "../../Utility/Utility";
import UserDownlineStats from "../../Components/dash/dashboard/UserDownlineStats";

const Dashboard = () => {

  const auth = useUserAuth();
  const history = useNavigate();
  const walletContext = useWalletContext();
  const callAPI = useCallAPI();
  const helper = useHelpers();
  const readContract = useReadContract();

  const [stats, setStats] = useState(false);
  const [arbAmount, setArbAmount] = useState(0);

  //Get user balance
  useEffect(() => {
      if(auth.appBootstrapped === true && auth.loggedIn === false) {
        history('/connect');
        return;
      }
      if(walletContext.walletConnected === true) {
          getResults();
      }
  }, [auth, walletContext.walletConnected]);

  const getResults = async () => {
    const res = await callAPI("dashboard");      
    if(res.status && res.data) {
        setStats(res.data);
    } else {
        setStats([]);
    }

    const arbRes = await callAPI("getArbitrageRecords");
    if(arbRes.data.records) {
        const cost = await readContract.getCost(arbRes.data.stats.count*1800);
        arbRes.data.stats.bnb = cost.int;
        setArbAmount(arbRes.data.stats.count*18);
    }
  }

  return (
    <div className="disk-outer mx-auto max-w-[1200px]">
      {arbAmount > 0 ? <div className="p-5 max-w-lg mx-auto">
        <div className="text-center my-7 text-white">
            <div className="font-bold font-orbitron text-primary text-6xl animate-pulse mb-2"><helper.AmountToCurrency amount={arbAmount} decimals={0} /></div>
            <div className="font-bold font-oxanium text-2xl">Arbitrage Contribution</div>
            <p className="text-gray-400 mb-4">Total contribution into the Plan18's BNB Pool from successfull arbitrage trades.</p>
            <Link to={'/contributions'}><Button color="amber">View Transactions</Button></Link>
        </div>
      </div>: <LoadingData />}
      {/* <div className="pt-7 max-w-[900px] mx-auto px-5 flex justify-center gap-4">
        <Link to={'/contributions'}><img src={arbiImg} className="shadow-xl border-4 hover:border-indigo-400 duration-300 border-primary rounded-2xl" /></Link>
        <Link to={'/bonusPool'}><img src={bonusPoolImg} className="shadow-xl border-4 hover:border-indigo-400 duration-300  border-primary rounded-2xl" /></Link>
      </div> */}
      <div className="flex justify-center min-h-[500px]">
        <PurchasePack />
      </div>
      <ClaimRewards />
      <div className="mb-5 max-w-[1054px] mx-auto">
        <CopyLink
          title="MY UNIQUE LINK"
          link={auth?.userBootstrap?.userData?.refLink}
        />
      </div>
      {stats && <UserDownlineStats stats={stats.downlineStats} />}
      <div className="mb-5 max-w-[1054px] mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div>
            <UserStats stats={stats.userStats} />
          </div>
          <div>
            <SiteStats stats={stats.siteStats} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
