import { useEffect, useState } from "react";
import Countdown from "react-countdown";
import toast from "react-hot-toast";
import { ClipLoader } from "react-spinners";
import Swal from "sweetalert2";
import { formatGwei } from "viem";
import disk from "../../../Assets/disk-1.png";
import { useUserAuth } from "../../../Contexts/authContext";
import { useWalletContext } from "../../../Contexts/walletContext";
import { useCallAPI } from "../../../Hooks/callAPI";
import { useHelpers } from "../../../Hooks/helpers";
import { PendingModal } from "../../../Hooks/pendingModal";
import { usePollAPI } from "../../../Hooks/pollAPI";
import { useReadContract } from "../../../Hooks/readContract";
import { useWriteContract } from "../../../Hooks/writeContract";
import { DetailsModal } from "../../modals/DetailsModal";
import { useDetailsModal } from "../../../Hooks/detailsModal";
import { Button } from "@material-tailwind/react";

const ClaimRewards = () => {

  const helper = useHelpers();
  const auth = useUserAuth();
  const callAPI = useCallAPI();
  const walletContext = useWalletContext();
  const writeContract = useWriteContract();
  const readContract = useReadContract();
  const pollResults = usePollAPI();
  const detailsModal = useDetailsModal();

  const [blockDiv, setBlockDiv] = useState(false);
  const [unclaimedRewards, setUnclaimedRewards] = useState(0);
  const [claimWalletBalance, setClaimWalletBalance] = useState(0);
  const [nextTransfer, setNextTransfer] = useState(0);
  const [nextClaim, setNextClaim] = useState(0);
  const [claimablePercent, setClaimablePercent] = useState(0);

  //Get user balance
  useEffect(() => {
      if(auth.appBootstrapped === true && walletContext.walletConnected === true) {
        getChainData();
      }
  }, [auth, walletContext.walletConnected]);

  const getChainData = async () => {

      const res1 = await readContract.read('getUnclaimedRewardsOfAllPacks', [ walletContext.userAddress ]);
      setUnclaimedRewards(formatGwei(res1.toString()*1));
      
      const res2 = await readContract.read('claimWallet', [ walletContext.userAddress ]);
      setClaimWalletBalance(formatGwei(res2.toString()*1));
      
      const res3 = await readContract.read('users', [ walletContext.userAddress ]);
      const res4 = await readContract.read('CLAIM_COOLDOWN');
      const res5 = await readContract.read('CLAIMABLE_PERCENT');
      const res6 = await readContract.read('MOVE_TO_CLAIM_WALLET_COOLDOWN');
      const res7 = await readContract.read('moveToClaimWalletCooldown',  [ walletContext.userAddress ]);
      setClaimablePercent(res5.toString()*1);

      setNextClaim(res3[7].toString()*1 + res4.toString()*1);
      setNextTransfer(res7.toString()*1 + res6.toString()*1);

  }

  //Trasnfer to claim wallet
  const transferToClaimWallet = async () => {

      if(unclaimedRewards === 0) {
        toast.error('Nothing to claim');
        return;
      }

      //Check if the Withdrawals is open from contract
      const withdOpen = await readContract.read('CLAIM_ACTIVE');
      if(!withdOpen) {
          toast.error('Claiming is paused currently. Please check back later.');
          return;
      }

      setBlockDiv('transfer');
      await writeContract.ContractWrite({ writeFn: "MoveUnclaimedToClaimWallet", inputData: [], successCallback: successCallback });
      setBlockDiv(false);
  }

  //Claim all selected packs rewards
  const claimPackRewards = async (proceed = false) => {

    if(claimWalletBalance*1 === 0) {
      toast.error('Transfer to claim wallet first');
      return;
    }

    if(proceed !== true) {
      detailsModal.open();
      return;
    }

    //Check if the Withdrawals is open from contract
    const withdOpen = await readContract.read('CLAIM_ACTIVE');
    if(!withdOpen) {
        toast.error('Claiming is paused currently. Please check back later.');
        return;
    }

    setBlockDiv('claim');
    await writeContract.ContractWrite({ writeFn: "claimMultiplePacks", inputData: [], successCallback: successCallback });
    setBlockDiv(false);
  }
  
  const successCallback = (hash) => {
      return new Promise(resolve => {
          (async () => {
              const apiToPoll = () => callAPI("checkChainTransaction/main/" + hash);
              const pollContinueCondition = (res) => res.data.state === "pending";
              const pollRes = await pollResults(apiToPoll, pollContinueCondition, 5000);
              resolve();
              if(pollRes.data.state == "success") {
                  //Show success popup
                  Swal.fire({
                      title: "Transaction Successful",
                      text: "Transaction processed successfully.",
                      icon: 'success'
                  });
                  await auth.getAppBootstrap();
              } else if(pollRes.data.state == "failed") {
                  toast.error("Failed to claim.");
              }
          })();
      });
  }

  return (
    <>
      <div className="md:flex justify-center min-h-[500px]">
        <div className="disk_wrapper type-2 mx-auto relative">
          <img className="w-full" src={disk} alt="Disk" />
          <div className="disk_top text-center absolute left-1/2 -translate-x-1/2 z-10">
            <h4 className="text-white txt_shadow_one font-orbitron font-bold">
              TRANSFER TO
              <span className="block">CLAIM WALLET</span>
            </h4>
          </div>
          <div className="middle_point absolute left-1/2 -translate-x-1/2 z-10">
          <button className="w-full h-full border-[3px] sm:border-[5px] border-white bg-[#211F1D] rounded-full inline-flex flex-col items-center justify-center leading-tight sm:leading-[1] font-orbitron text-white text-xl xl:text-[28px] uppercase transition duration-300 hover:border-primary hover:text-primary">
            {writeContract.modalShow ?
              <>
                <span className='hidden sm:block text-xs mb-2'>Validating</span>
                <PendingModal show={writeContract.modalShow} data={writeContract.modalData} />
              </>
            :
              <>
                {nextTransfer > Date.now()/1000 ?
                  <>
                    <span className="block text-xs">TRANSFER IN</span>
                    <p className="text-xs"><Countdown onComplete={getChainData} date={nextTransfer*1000} daysInHours={true} renderer={({ days, hours, minutes, seconds }) => { return <span>{days} : {hours} : {minutes} : {seconds}</span> }} /></p>
                  </>
                :
                  <>
                    {blockDiv === 'transfer' ? 
                      <>
                      <ClipLoader
                        color='#F8892B'
                        loading={true}
                        size={40}
                        aria-label="Loading"
                        data-testid="loader"
                        className='mx-auto'
                      />
                    </>
                    :
                      <div onClick={transferToClaimWallet}>
                        <span className="block text-sm">TRANSFER</span>
                        NOW
                      </div>
                    }
                  </>
                }
              </>
            }
          </button>
          </div>
          <div className="disk_bottom  text-center absolute left-1/2 -translate-x-1/2 z-10">
            <p className="text-white txt_shadow_one font-oxanium font-bold text-md leading-[1.1]">
              Unclaimed Rewards
            </p>
            <strong className="text-white block txt_shadow_one font-oxanium font-bold text-3xl xl:text-[42px] leading-tight">
              <helper.AmountToCurrency amount={unclaimedRewards*1} decimals={4} />
            </strong>
          </div>
        </div>
        <div className="disk_wrapper type-2 mx-auto relative">
          <img className="w-full" src={disk} alt="Disk" />
          <div className="disk_top text-center absolute left-1/2 -translate-x-1/2 z-10">
            <h4 className="text-white txt_shadow_one font-orbitron font-bold">
              CLAIM
              <span className="block">TO WALLET</span>
            </h4>
          </div>
          <div className="middle_point absolute left-1/2 -translate-x-1/2 z-10">
          <button className="w-full h-full border-[3px] sm:border-[5px] border-white bg-[#211F1D] rounded-full inline-flex flex-col items-center justify-center leading-tight sm:leading-[1] font-orbitron text-white text-xl xl:text-[28px] uppercase transition duration-300 hover:border-primary hover:text-primary">
            {writeContract.modalShow ?
              <>
                <span className='hidden sm:block text-xs mb-2'>Validating</span>
                <PendingModal show={writeContract.modalShow} data={writeContract.modalData} />
              </>
            :
              <>
                {nextClaim > Date.now()/1000 ?
                  <>
                    <span className="block text-base xl:text-md">CLAIM IN</span>
                    <p className="text-xs"><Countdown onComplete={getChainData} date={nextClaim*1000} daysInHours={true} renderer={({ days, hours, minutes, seconds }) => { return <span>{days} : {hours} : {minutes} : {seconds}</span> }} /></p>
                  </>
                :
                  <>
                    {blockDiv === 'claim' ? 
                      <>
                        <ClipLoader
                          color='#F8892B'
                          loading={true}
                          size={40}
                          aria-label="Loading"
                          data-testid="loader"
                          className='mx-auto'
                        />
                      </>
                    :
                      <div onClick={claimPackRewards}>
                        <span className="block text-base xl:text-2xl">CLAIM</span>
                        NOW
                      </div>
                    }
                  </>
                }
              </>
            }
          </button>
          </div>
          <div className="disk_bottom  text-center absolute left-1/2 -translate-x-1/2 z-10">
            <p className="text-white txt_shadow_one font-oxanium font-bold text-md leading-[1.1]">
              Available Claim Wallet
            </p>
            <strong className="text-white block txt_shadow_one font-oxanium font-bold text-3xl xl:text-[42px] leading-tight">
              <helper.AmountToCurrency amount={claimWalletBalance*1} decimals={4} />
            </strong>
          </div>
        </div>
      </div>
      <DetailsModal Component={ProceedModal} props={{ proceed: () => claimPackRewards(true), data: { unclaimedRewards: unclaimedRewards, claimWalletBalance: claimWalletBalance, claimablePercent: claimablePercent }, dismiss: detailsModal.close }} hook={detailsModal} />
    </>
  );
};
export default ClaimRewards;

const ProceedModal = ({ proceed, data, dismiss }) => {
  const helper = useHelpers();
  return (
      <div className="py-2 font-oxanium">
          <div className="mt-4 text-center text-xl font-bold text-gray-900">Claim Summary</div>
          <table className="w-full max-w-lg mx-auto mt-10 font-oxanium">
            {/* <tr>
              <td className="p-3 border-b-[1px] border-gray-200 text-left text-gray-800">Unclaimed Rewards + Claim Wallet</td>
              <td className="p-3 border-b-[1px] border-gray-200 text-right font-bold text-lg text-indigo-500"><helper.AmountToCurrency amount={data.unclaimedRewards*1 + data.claimWalletBalance*1} decimals={5} /></td>
            </tr> */}
            <tr>
              <td className="p-3 border-b-[1px] border-gray-200 text-left text-gray-800">Rewards sent to your wallet <small>({data.claimablePercent/100}%)</small></td>
              <td className="p-3 border-b-[1px] border-gray-200 text-right font-bold text-lg text-indigo-500"><helper.AmountToCurrency amount={(data.unclaimedRewards*1 + data.claimWalletBalance*1)*data.claimablePercent/10000} decimals={5} /></td>
            </tr>
            <tr>
              <td className="p-3 border-b-[1px] border-gray-200 text-left text-gray-800">Your Claim wallet balance after this Claim</td>
              <td className="p-3 border-b-[1px] border-gray-200 text-right font-bold text-lg text-green-500"><helper.AmountToCurrency amount={data.unclaimedRewards*1 + data.claimWalletBalance*1 - (data.unclaimedRewards*1 + data.claimWalletBalance*1)*data.claimablePercent/10000} decimals={3} /></td>
            </tr>
          </table>
          <div className="mt-10 mb-10 flex justify-center">
            <Button onClick={() => { proceed(); dismiss(); }} color="amber" variant="gradient" size="lg">Proceed to Claim</Button>
            <Button onClick={() => { dismiss(); }} color="red" size="lg" variant="outlined" className="ml-3">Cancel</Button>
          </div>
      </div>
  );
}