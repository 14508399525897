export const usePollAPI = () => {

    const pollResults = async (func, funcCondition, intervalMS) => {
        let result = await func();
        //Check condition in loop if satisfied
        while(funcCondition(result)) {
            await wait(intervalMS);
            result = await func();
        }
        return result;
    };
      
    const wait = (ms = 5000) => {
        return new Promise(resolve => {
            setTimeout(resolve, ms);
        });
    };

    return pollResults;

};