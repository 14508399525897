import { useEffect, useState } from "react";
import Countdown from "react-countdown";
import toast from "react-hot-toast";
import { ClipLoader } from "react-spinners";
import Swal from "sweetalert2";
import { formatGwei } from "viem";
import disk from "../../../Assets/disk-1.png";
import { useUserAuth } from "../../../Contexts/authContext";
import { useWalletContext } from "../../../Contexts/walletContext";
import { useCallAPI } from "../../../Hooks/callAPI";
import { useHelpers } from "../../../Hooks/helpers";
import { PendingModal } from "../../../Hooks/pendingModal";
import { usePollAPI } from "../../../Hooks/pollAPI";
import { useReadContract } from "../../../Hooks/readContract";
import { useWriteContract } from "../../../Hooks/writeContract";

const RefundContract = ({ pack_id }) => {

  const helper = useHelpers();
  const auth = useUserAuth();
  const callAPI = useCallAPI();
  const walletContext = useWalletContext();
  const writeContract = useWriteContract();
  const readContract = useReadContract();
  const pollResults = usePollAPI();
  const [blockDiv, setBlockDiv] = useState(false);
  const [refundableAmount, setRefundableAmount] = useState(0);

  //Get user balance
  useEffect(() => {
      if(auth.appBootstrapped === true && walletContext.walletConnected === true) {
        getChainData();
      }
  }, [auth, walletContext.walletConnected]);

  const getChainData = async () => {
    const res1 = await readContract.read('packs', [ pack_id ]);
    setRefundableAmount(formatGwei(res1[7].toString()*1));
  }

  //Claim all selected packs rewards
  const refundPack = async () => {
      if(refundableAmount == 0) {
        toast.error('Nothing to refund');
        return;
      }
      setBlockDiv('claim');
      await writeContract.ContractWrite({ writeFn: "refundPack", inputData: [ pack_id ], successCallback: successCallback });
      setBlockDiv(false);
  }

  const successCallback = (hash) => {
      return new Promise(resolve => {
          (async () => {
              const apiToPoll = () => callAPI("checkChainTransaction/main/" + hash);
              const pollContinueCondition = (res) => res.data.state === "pending";
              const pollRes = await pollResults(apiToPoll, pollContinueCondition, 5000);
              resolve();
              if(pollRes.data.state == "success") {
                  //Show success popup
                  Swal.fire({
                      title: "Refund Successful",
                      text: "Refund amount transferred to your wallet!",
                      icon: 'success'
                  });
                  await auth.getAppBootstrap();
              } else if(pollRes.data.state == "failed") {
                  toast.error("Failed to refund.");
              }
          })();
      });
  }

  return (
    <>
    {writeContract.modalShow ?
      <button className="bg-four w-[calc(100%_+_10px)] h-32 border-[5px] border-white rounded-[50px] inline-flex flex-col items-center justify-center leading-tight sm:leading-[1] font-orbitron text-white text-xl sm:text-2xl uppercase transition duration-300 hover:border-primary hover:text-primary absolute -bottom-[5px] -left-[5px] right-0 ">
        <span className='hidden sm:block text-xs mb-2'>Processing</span>
        <PendingModal show={writeContract.modalShow} data={writeContract.modalData} />
      </button>
    :
      <button  className="bg-four w-[calc(100%_+_10px)] h-32 border-[5px] border-white rounded-[50px] inline-flex flex-col items-center justify-center leading-tight sm:leading-[1] font-orbitron text-white text-xl sm:text-2xl uppercase transition duration-300 hover:border-primary hover:text-primary absolute -bottom-[5px] -left-[5px] right-0 ">
        {!blockDiv ? 
          <div onClick={refundPack}>
            <span className="block text-xs sm:text-lg">CANCEL CONTRACT</span>
            GET <span><helper.AmountToCurrency amount={refundableAmount} /></span> REFUND
          </div>
        :
          <ClipLoader
            color='#F8892B'
            loading={true}
            size={40}
            aria-label="Loading"
            data-testid="loader"
            className='mx-auto'
          />
        }
      </button>
    }
    </>
   
  );
};
export default RefundContract;