import appConfig from "../../../Utility/Config/config";

const Footer = () => {
  return (
    <div className="text-right text-sm mt-10">
      <span className="bg-white/80 px-3 py-2 rounded-md shadow-lg">&copy; Plan18.io | v{appConfig.version}</span>
    </div>
  );
};
export default Footer;
