import React from "react";
import { Link } from "react-router-dom";

const MenuDash = () => {
  return (
    <ul className="me_nu flex justify-around sm:justify-content-between sm:space-x-5">
      <li>
        <Link to={'/'}>
          <svg
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2327_34)">
              <path
                d="M60 44.5359V15.4641C60 6.9235 53.0765 0 44.5359 0H15.4641C6.9235 0 0 6.9235 0 15.4641V44.5359C0 53.0765 6.9235 60 15.4641 60H44.5359C53.0765 60 60 53.0765 60 44.5359Z"
                fill="url(#paint0_linear_2327_34)"
              />
              <path
                d="M60 39.9362V44.5312C60 48.6337 58.3703 52.5683 55.4693 55.4692C52.5684 58.3702 48.6338 59.9999 44.5313 59.9999H31.5914L16.766 45.1745L18.2473 35.3249L13.1895 30.2671L46.7051 26.646L60 39.9362Z"
                fill="url(#paint1_linear_2327_34)"
              />
              <path
                d="M46.7098 26.6462L33.0867 15.2345C32.2215 14.509 31.1285 14.1113 29.9994 14.1113C28.8703 14.1113 27.7773 14.509 26.9121 15.2345L13.2902 26.6462C11.5617 28.0947 12.5871 30.9118 14.8406 30.9118H16.0758V43.4896C16.0758 44.127 16.329 44.7384 16.7797 45.1891C17.2305 45.6399 17.8418 45.8931 18.4793 45.8931H25.3652V37.7697C25.3651 37.161 25.4848 36.5582 25.7177 35.9958C25.9506 35.4334 26.292 34.9224 26.7224 34.492C27.1528 34.0616 27.6638 33.7202 28.2262 33.4874C28.7885 33.2545 29.3913 33.1347 30 33.1349C30.6086 33.1349 31.2113 33.2548 31.7736 33.4877C32.336 33.7206 32.8469 34.062 33.2773 34.4924C33.7076 34.9228 34.049 35.4337 34.282 35.996C34.5149 36.5583 34.6348 37.161 34.6348 37.7697V45.8931H41.5207C42.1581 45.8931 42.7695 45.6399 43.2202 45.1891C43.671 44.7384 43.9242 44.127 43.9242 43.4896V30.9118H45.1582C47.4141 30.9118 48.4383 28.0947 46.7098 26.6462Z"
                fill="white"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_2327_34"
                x1="4.5293"
                y1="4.5293"
                x2="55.4707"
                y2="55.4707"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F8892B" />
                <stop offset="1" stopColor="#40C5E6" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_2327_34"
                x1="19.6418"
                y1="23.8147"
                x2="53.3848"
                y2="57.5565"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopOpacity="0.35" />
                <stop offset="1" stopOpacity="0" />
              </linearGradient>
              <clipPath id="clip0_2327_34">
                <rect width="60" height="60" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>
      </li>
      <li>
        <Link to={'/contracts'}>
          <svg
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2327_41)">
              <path
                d="M60 44.5359V15.4641C60 6.9235 53.0765 0 44.5359 0H15.4641C6.9235 0 0 6.9235 0 15.4641V44.5359C0 53.0765 6.9235 60 15.4641 60H44.5359C53.0765 60 60 53.0765 60 44.5359Z"
                fill="url(#paint0_linear_2327_41)"
              />
              <path
                d="M60.0004 27.6633V44.5312C60.0004 48.6338 58.3707 52.5684 55.4697 55.4693C52.5687 58.3703 48.6342 60 44.5316 60H27.6566L13.7383 46.0746L21.6566 34.2891L13.7383 26.3672L26.3676 13.7402L33.6602 21.0328L46.075 13.7402L60.0004 27.6633Z"
                fill="url(#paint1_linear_2327_41)"
              />
              <path
                d="M42.7719 12.4219H36.9359C34.2811 12.4219 32.1289 14.5741 32.1289 17.2289V23.0648C32.1289 25.7197 34.2811 27.8719 36.9359 27.8719H42.7719C45.4267 27.8719 47.5789 25.7197 47.5789 23.0648V17.2289C47.5789 14.5741 45.4267 12.4219 42.7719 12.4219Z"
                fill="white"
              />
              <path
                d="M23.0648 12.4219H17.2289C14.5741 12.4219 12.4219 14.5741 12.4219 17.2289V23.0648C12.4219 25.7197 14.5741 27.8719 17.2289 27.8719H23.0648C25.7197 27.8719 27.8719 25.7197 27.8719 23.0648V17.2289C27.8719 14.5741 25.7197 12.4219 23.0648 12.4219Z"
                fill="white"
              />
              <path
                d="M42.7719 32.1279H36.9359C34.2811 32.1279 32.1289 34.2801 32.1289 36.935V42.7709C32.1289 45.4257 34.2811 47.5779 36.9359 47.5779H42.7719C45.4267 47.5779 47.5789 45.4257 47.5789 42.7709V36.935C47.5789 34.2801 45.4267 32.1279 42.7719 32.1279Z"
                fill="white"
              />
              <path
                d="M23.0648 32.1279H17.2289C14.5741 32.1279 12.4219 34.2801 12.4219 36.935V42.7709C12.4219 45.4257 14.5741 47.5779 17.2289 47.5779H23.0648C25.7197 47.5779 27.8719 45.4257 27.8719 42.7709V36.935C27.8719 34.2801 25.7197 32.1279 23.0648 32.1279Z"
                fill="white"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_2327_41"
                x1="4.5293"
                y1="4.5293"
                x2="55.4707"
                y2="55.4707"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F8892B" />
                <stop offset="1" stopColor="#40C5E6" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_2327_41"
                x1="20.0547"
                y1="20.0543"
                x2="55.4711"
                y2="55.4707"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopOpacity="0.35" />
                <stop offset="1" stopOpacity="0" />
              </linearGradient>
              <clipPath id="clip0_2327_41">
                <rect width="60" height="60" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>
      </li>
      <li>
        <Link to={'/referrals'}>
          <svg
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2327_52)">
              <path
                d="M60 44.5359V15.4641C60 6.9235 53.0765 0 44.5359 0H15.4641C6.9235 0 0 6.9235 0 15.4641V44.5359C0 53.0765 6.9235 60 15.4641 60H44.5359C53.0765 60 60 53.0765 60 44.5359Z"
                fill="url(#paint0_linear_2327_52)"
              />
              <path
                d="M60 35.4689V44.5311C60 48.6336 58.3703 52.5682 55.4693 55.4691C52.5684 58.3701 48.6338 59.9998 44.5312 59.9998H36.9902L12.6562 35.6646L20.6977 27.9889L17.4258 24.717L24.266 17.8768L32.2406 25.8455L33.402 25.4939L37.2691 29.3611L39.1559 28.2971L35.5465 24.6877L42.4113 17.8721L60 35.4689Z"
                fill="url(#paint1_linear_2327_52)"
              />
              <path
                d="M29.9996 34.1577C32.2554 34.1577 34.4188 35.0538 36.0139 36.6489C37.609 38.244 38.5051 40.4074 38.5051 42.6632C38.5051 42.8736 38.4215 43.0754 38.2727 43.2242C38.1239 43.373 37.9221 43.4565 37.7117 43.4565H22.2875C22.0771 43.4565 21.8753 43.373 21.7265 43.2242C21.5777 43.0754 21.4941 42.8736 21.4941 42.6632C21.4941 40.4074 22.3903 38.244 23.9853 36.6489C25.5804 35.0538 27.7438 34.1577 29.9996 34.1577Z"
                fill="white"
              />
              <path
                d="M30.0008 33.7804C32.6731 33.7804 34.8395 31.614 34.8395 28.9417C34.8395 26.2694 32.6731 24.103 30.0008 24.103C27.3285 24.103 25.1621 26.2694 25.1621 28.9417C25.1621 31.614 27.3285 33.7804 30.0008 33.7804Z"
                fill="white"
              />
              <path
                d="M47.5777 35.1035C47.5774 35.314 47.4936 35.5158 47.3446 35.6646C47.1956 35.8133 46.9937 35.8969 46.7832 35.8969H37.1281C37.0695 35.8359 37.0109 35.7738 36.9488 35.7141C36.0878 34.8497 35.0732 34.1534 33.957 33.6609C34.8757 32.8917 35.5499 31.8712 35.8971 30.7245C36.2443 29.5778 36.2494 28.3546 35.9117 27.2051C37.2025 26.6891 38.6 26.4972 39.9821 26.6462C41.3641 26.7952 42.6886 27.2807 43.8397 28.06C44.9908 28.8393 45.9334 29.8888 46.585 31.1167C47.2366 32.3446 47.5775 33.7134 47.5777 35.1035Z"
                fill="white"
              />
              <path
                d="M39.073 26.2193C41.7454 26.2193 43.9117 24.053 43.9117 21.3807C43.9117 18.7083 41.7454 16.542 39.073 16.542C36.4007 16.542 34.2344 18.7083 34.2344 21.3807C34.2344 24.053 36.4007 26.2193 39.073 26.2193Z"
                fill="white"
              />
              <path
                d="M26.0426 33.6608C24.926 34.1533 23.911 34.8495 23.0496 35.7139C22.9898 35.7737 22.9324 35.8311 22.8715 35.8967H13.2152C13.111 35.8969 13.0078 35.8765 12.9114 35.8367C12.8151 35.7968 12.7276 35.7384 12.6539 35.6647C12.5802 35.591 12.5218 35.5035 12.482 35.4072C12.4421 35.3108 12.4217 35.2076 12.4219 35.1034C12.4227 33.7135 12.7639 32.345 13.4157 31.1174C14.0675 29.8898 15.01 28.8406 16.1609 28.0614C17.3118 27.2822 18.6361 26.7967 20.0179 26.6474C21.3997 26.4981 22.7971 26.6895 24.0879 27.2049C23.7502 28.3545 23.7553 29.5776 24.1025 30.7244C24.4497 31.8711 25.124 32.8916 26.0426 33.6608Z"
                fill="white"
              />
              <path
                d="M20.9266 26.2193C23.5989 26.2193 25.7652 24.053 25.7652 21.3807C25.7652 18.7083 23.5989 16.542 20.9266 16.542C18.2542 16.542 16.0879 18.7083 16.0879 21.3807C16.0879 24.053 18.2542 26.2193 20.9266 26.2193Z"
                fill="white"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_2327_52"
                x1="4.5293"
                y1="4.5293"
                x2="55.4707"
                y2="55.4707"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F8892B" />
                <stop offset="1" stopColor="#40C5E6" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_2327_52"
                x1="21.45"
                y1="20.6893"
                x2="55.8504"
                y2="55.0896"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopOpacity="0.35" />
                <stop offset="1" stopOpacity="0" />
              </linearGradient>
              <clipPath id="clip0_2327_52">
                <rect width="60" height="60" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>
      </li>
      <li>
        <Link to={'/payments'}>
          <svg
            width="60"
            height="60"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2327_65)">
              <path
                d="M60 44.5359V15.4641C60 6.9235 53.0765 0 44.5359 0H15.4641C6.9235 0 0 6.9235 0 15.4641V44.5359C0 53.0765 6.9235 60 15.4641 60H44.5359C53.0765 60 60 53.0765 60 44.5359Z"
                fill="url(#paint0_linear_2327_65)"
              />
              <path
                d="M59.9996 29.3309V44.5312C59.9996 48.6338 58.3699 52.5684 55.4689 55.4693C52.568 58.3703 48.6334 60 44.5309 60H29.3281L13.3906 44.0625L19.7188 38.7129L13.3965 32.3848L19.8805 27.1969L13.4727 20.7891L46.4891 15.8086L59.9996 29.3309Z"
                fill="url(#paint1_linear_2327_65)"
              />
              <path
                d="M44.1445 26.5664H15.8555C13.9591 26.5664 12.4219 28.1034 12.4219 29.9994C12.4219 31.8954 13.9591 33.4324 15.8555 33.4324H44.1445C46.0409 33.4324 47.5781 31.8954 47.5781 29.9994C47.5781 28.1034 46.0409 26.5664 44.1445 26.5664Z"
                fill="white"
              />
              <path
                d="M44.1445 14.8936H15.8555C13.9591 14.8936 12.4219 16.4306 12.4219 18.3266C12.4219 20.2226 13.9591 21.7596 15.8555 21.7596H44.1445C46.0409 21.7596 47.5781 20.2226 47.5781 18.3266C47.5781 16.4306 46.0409 14.8936 44.1445 14.8936Z"
                fill="white"
              />
              <path
                d="M44.1445 38.2397H15.8555C13.9591 38.2397 12.4219 39.7768 12.4219 41.6728C12.4219 43.5688 13.9591 45.1058 15.8555 45.1058H44.1445C46.0409 45.1058 47.5781 43.5688 47.5781 41.6728C47.5781 39.7768 46.0409 38.2397 44.1445 38.2397Z"
                fill="white"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_2327_65"
                x1="4.5293"
                y1="4.5293"
                x2="55.4707"
                y2="55.4707"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F8892B" />
                <stop offset="1" stopColor="#40C5E6" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_2327_65"
                x1="17.1266"
                y1="17.127"
                x2="55.4703"
                y2="55.4707"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopOpacity="0.35" />
                <stop offset="1" stopOpacity="0" />
              </linearGradient>
              <clipPath id="clip0_2327_65">
                <rect width="60" height="60" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>
      </li>
      <li>
        <Link to={'/activities'}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={512}
            height={512}
            x={0}
            y={0}
            viewBox="0 0 512 512"
            style={{ enableBackground: "new 0 0 512 512" }}
            xmlSpace="preserve"
            className=""
          >
            <g>
              <linearGradient
                id="a"
                x1="38.65"
                x2="473.35"
                y1="38.65"
                y2="473.35"
                gradientTransform="rotate(-90 256 256)"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopOpacity={1} stopColor="#ee8d3b" offset="0.007722007722007722" />
                <stop stopOpacity={1} stopColor="#45c1e0" offset={1} />
              </linearGradient>
              <linearGradient
                id="b"
                x1="158.65"
                x2="473.35"
                y1="158.66"
                y2="473.35"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset={0} stopOpacity=".35" />
                <stop offset={1} stopOpacity={0} />
              </linearGradient>
              <g data-name="Layer 2">
                <g data-name="22.Comment">
                  <rect
                    width={512}
                    height={512}
                    fill="url(#a)"
                    rx="131.96"
                    transform="rotate(90 256 256)"
                    opacity={1}
                    data-original="url(#a)"
                    className=""
                  />
                  <path
                    fill="url(#b)"
                    d="M512 300.77V380a132 132 0 0 1-132 132h-79.24L148.23 359.47l-13.61-77.62L169.34 148H359.2z"
                    opacity={1}
                    data-original="url(#b)"
                  />
                  <path
                    fill="#ffffff"
                    d="M256.45 106A150 150 0 0 0 106 256.45C106.24 338.71 174.85 406 257.11 406H406V257.11c0-82.26-67.3-150.87-149.55-151.11zm-78.19 81.05a14.58 14.58 0 0 1 10.36-4.29h52.73a14.65 14.65 0 1 1 0 29.29h-52.73a14.64 14.64 0 0 1-10.36-25zM333.74 325a14.62 14.62 0 0 1-10.36 4.29H188.62a14.65 14.65 0 1 1 0-29.3h134.76a14.65 14.65 0 0 1 10.36 25zm0-58.59a14.62 14.62 0 0 1-10.36 4.29H188.62a14.65 14.65 0 1 1 0-29.3h134.76a14.65 14.65 0 0 1 10.36 25z"
                    data-name="22.Comment"
                    opacity={1}
                    data-original="#ffffff"
                    className=""
                  />
                </g>
              </g>
            </g>
          </svg>
        </Link>
      </li>
    </ul>
  );
};

export default MenuDash;
