import React, { useEffect, useState } from "react";
import { useUserAuth } from '../../Contexts/authContext';
import { useWalletContext } from "../../Contexts/walletContext";
import { useCallAPI } from "../../Hooks/callAPI";
import { EmptyRecords, LoadingData, TitlePrimary } from "../../Utility/Utility";
import { useHelpers } from "../../Hooks/helpers";
import Timestamp from "react-timestamp";
import { Button, Card, Chip } from "@material-tailwind/react";
import { toast } from "react-hot-toast";
import parse from 'html-react-parser';
import { useReadContract } from "../../Hooks/readContract";

const ArbigrateLogs = () => {

    const auth = useUserAuth();
    const helper = useHelpers();
    const callAPI = useCallAPI();
    const walletContext = useWalletContext();
    const readContract = useReadContract();

    const [data, setData] = useState([]);
    const [stats, setStats] = useState({});
    const [dataLoadFull, setDataLoadFull] = useState(false);

    //Get user balance
    useEffect(() => {
        if(auth.appBootstrapped === true && walletContext.walletConnected === true) {
            getResults(0);
        }
    }, [auth, walletContext.walletConnected]);

    const getResults = async (offset) => {
        const res = await callAPI("getArbitrageRecords" + "/" + offset);
        if(res.data.records) {
            setData([...data, ...res.data.records]);
            //Get cost in bnb
            const cost = await readContract.getCost(res.data.stats.count*1800);
            res.data.stats.bnb = cost.int;
            setStats(res.data.stats);

        } else {
            toast.success("All Rows Loaded");
            setDataLoadFull(true);
        }
    }

    return (
        <>
            <div className="border-[5px] border-white rounded-2xl_next bg-three shadow-one p-4 md:p-7 pb-5">
                <div className="text-center mb-5 md:mb-7">
                    <TitlePrimary>Arbitrage Contribution</TitlePrimary>
                    <p className="text-gray-400">Total contribution into the Plan18's BNB Pool from successfull arbitrage trades.</p>
                </div>
                <div className="flex justify-center mb-8">
                    <div className="bg-primary rounded-xl px-4 py-3 font-oxanium">
                        <h3 className="text-3xl font-bold"><helper.AmountToCurrency amount={stats.count*18} /> <small className="text-lg">(<helper.AmountToCurrency amount={stats.bnb} decimals={0} prefix="" /> BNB)</small></h3>
                        <p>Total Contribution</p>
                    </div>
                </div>
                
                {data && data.length > 0 &&
                    <>
                        <div className="table-custom-scroller">
                            <table className="table-custom w-full font-oxanium">
                                <thead>
                                    <tr>
                                        <th>From Wallet</th>
                                        <th>Amount In</th>
                                        <th>Hash</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {data && data.length > 0 && data.map((e, i) => {
                                    return (
                                        <tr key={i}>
                                            <td><Chip className="inline" color='indigo' value={helper.trimAddress({ address: e.address, copy: true })} /></td>
                                            <td className="max-w-xs"><helper.AmountToCurrency amount={e.packs*18} /></td>
                                            <td><Chip className="inline" value={helper.trimAddress({ address: e.hash, copy: true })} /></td>
                                            <td>{helper.ProcessDate(e.created)}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                            {!dataLoadFull && <Button color="teal" className="mt-4" onClick={() => getResults(data.length)}>Load More</Button>}
                        </div>
                    </>
                }
                {data && data.length === 0 &&
                    <EmptyRecords title='No Logs Found' description='Arbitrage logs will be listed here.' />
                }
                {data === false &&
                    <LoadingData />
                }
            </div>
            
        </>
    );
};
export default ArbigrateLogs;
