import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserAuth } from '../../Contexts/authContext';
import { useCallAPI } from '../../Hooks/callAPI';

const Logout = () => {

    const callAPI = useCallAPI();
    const history = useNavigate();
    const authContext = useUserAuth();

    // Call API to submit data when formdata is set
    useEffect(() => {
        (async () => {
            await callAPI("logout");
            localStorage.removeItem('userauthtoken');
            await authContext.getAppBootstrap();
            history('/');
            location.reload();
        })();
    }, []);

    return (
        <>
        </>
    );
};

export default Logout;