import { Button } from "@material-tailwind/react";
import appConfig from "../../../Utility/Config/config";

const LearnMore = () => {
  return (
    <div className="my-10 max-w-[1054px] mx-auto px-5">
      <div className="border-[5px] border-white rounded-2xl_next bg-six shadow-one p-4 md:p-7 pb-5">
        <div className="text-center">
            <div className="text-md sm:text-3xl font-orbitron uppercase text-white font-bold mb-4">Learn how Plan18 Works</div>
            <div className="space-x-4">
              <a href={appConfig.complan} target="blank"><Button color="amber" className="font-oxanium text-lg" size="sm">Download PDF</Button></a>
              <a href={appConfig.video} target="blank"><Button size="sm" className="font-oxanium text-lg">Watch Video</Button></a>
            </div>
        </div>
      </div>
    </div>
  );
};
export default LearnMore;
