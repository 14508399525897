import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import bgLoginPge from "../../Assets/bg.webp";
import disk from "../../Assets/disk-1.png";
import logo from "../../Assets/logo.png";
import { useUserAuth } from '../../Contexts/authContext';
import { useWalletContext } from '../../Contexts/walletContext';
import { useCallAPI } from '../../Hooks/callAPI';
import { useHelpers } from '../../Hooks/helpers';
import { useReadContract } from '../../Hooks/readContract';
import { useWriteContract } from '../../Hooks/writeContract';
import { usePollAPI } from '../../Hooks/pollAPI';
import Swal from 'sweetalert2';
import { PendingModal } from '../../Hooks/pendingModal';
import { ClipLoader } from 'react-spinners';
import ConnectedWallet from '../../Components/dash/layout/ConnectedWallet';
import appConfig from '../../Utility/Config/config';
import Footer from '../../Components/dash/layout/Footer';
import LearnMore from '../../Components/dash/layout/LearnMore';

const Connect = () => {
    const callAPI = useCallAPI();
    const history = useNavigate();
    const helpers = useHelpers();
    const authContext = useUserAuth();
    const walletContext = useWalletContext();
    const readContract = useReadContract();
    const writeContract = useWriteContract();
    const params = useParams();
    const pollResults = usePollAPI();

    const [blockDiv, setBlockDiv] = useState(false);
    const [referrerAddress, setReferrerAddress] = useState(null);
    const [userBalance, setUserBalance] = useState(false);

    //Check for authentication
    useEffect(() => {
      if(authContext.appBootstrapped === true && authContext.loggedIn === true) {
          history('/dashboard');
          return;
      }
    }, [authContext]);

    useEffect(() => {
        if(params.ref) {
            localStorage.setItem("referrerAddress", params.ref);
            setReferrerAddress(params.ref);
        } else {
            if(localStorage.getItem("referrerAddress") !== null && localStorage.getItem("referrerAddress") !== undefined) {
              setReferrerAddress(localStorage.getItem("referrerAddress"));
            }
        }
    }, []);

    //Get user balance
    useEffect(() => {
        if(walletContext.walletConnected === true) {
            (async () => {
                setUserBalance(await readContract.getUserBalance());
            })();
        }
    }, [walletContext.walletConnected]);

    const connectNow = async () => {

        setBlockDiv(true);
        try {

          if(!walletContext.walletConnected) {
            await walletContext.connectWallet();
          }
          if(walletContext.walletConnected !== true) { throw 'Failed to connect to wallet'; }

          //Check if the address is activated in the users contract
          const userRes = await readContract.read('isUserExists', [walletContext.userAddress]);
          if(userRes === true) { 
            postLogin();
          } else {
            //else activate the user into the contract
            //Check referrer
            const refRes = await callAPI("getReferrerAddress", { referrerAddress: referrerAddress });
            if(refRes.data.state === 1) { //Success
              setReferrerAddress(refRes.data.referrerAddress);
              if(refRes.data.referrerDefaulted == 'yes') {
                toast.success('Default referrer set');
              }
            } else {
                console.log(refRes.data);
                throw(refRes.data.error);
            }
            
            //If Presale active allow only 1 purchase per wallet
            const launched = await readContract.read('LAUNCHED_ON');
            if(launched.toString()*1 === 0) {
              //Check if user is whitelisted
              const isWhiteListed = await readContract.read('whitelistedAddress', [ walletContext.userAddress ]);
              if(!isWhiteListed) {
                throw 'Please wait for Official Launch'; 
              }
            }
            
            //Get required balance for pro purchase
            const res = await readContract.read('PACK_COST');
            
            const cost = await readContract.getCost(res.toString());
            if(userBalance.int < cost.int) { throw 'Insufficient Balance'; }

            purchaseNow(cost, refRes.data.referrerAddress);

          }

        } catch (error) {
            console.log(error);
            try {
              toast.error(error);
            } catch (error) {
              toast.error('Unexpected error occurred E001');
            }
            setBlockDiv(false);
        }
        
    }

    const purchaseNow = async (cost, referrer) => {
      setBlockDiv(true);
      await writeContract.ContractWrite({ writeFn: "activateId", inputData: [ referrer ], successCallback: purchaseCB, value: cost.bns});
      setBlockDiv(false);
    }

    const purchaseCB = (hash) => {
        return new Promise(resolve => {
            (async () => {
                const apiToPoll = () => callAPI("checkChainTransaction/main/" + hash);
                const pollContinueCondition = (res) => res.data.state === "pending";
                const pollRes = await pollResults(apiToPoll, pollContinueCondition, 5000);
                resolve();
                if(pollRes.data.state == "success") {
                    //Show success popup
                    Swal.fire({
                        title: "Activation Complete!",
                        text: "You can now access backoffice",
                        icon: 'success'
                    });
                    postLogin();
                } else if(pollRes.data.state == "failed") {
                    toast.error("Failed to activate.");
                }
            })();
        });
    }

    const postLogin = async () => {
        //If yes, create user auth 
        const res = await callAPI("connectWalletWithId", { address: walletContext.userAddress });
        if(res.data.state !== 1) { throw res.data.error };
        if(res.data.state === 1) { //Success
          localStorage.setItem('userauthtoken', res.data.auth_token);
          await authContext.getAppBootstrap();
          history('/');
        }
    }

    return (
      <>
        <div className="min-h-screen relative font-oxanium font-normal">
          <div
            className="w-full h-full absolute top-0 start-0 bottom-0 end-0 bg-cover bg-center-top"
            style={{ backgroundImage: `url(${bgLoginPge})` }}
          >
            <span className="w-full h-full bg-one block absolute top-0 left-0 right-0 bottom-0"></span>
          </div>
          <div className="min-h-screen flex flex-col justify-center z-10 relative overflow-hidden pt-10">
            <div className="absolute right-5 top-5">
              <ConnectedWallet />
            </div>
            <main>
              <div className="max-w-[1080px] mx-auto px-4 lg:px-8 py-8 flex items-center justify-center pt-20">
                <div className="disk_wrapper mx-auto sm:w-[780px] relative">
                  <img className="w-full" src={disk} alt="Disk" />
                  <div className="disk_top text-center absolute left-1/2 -translate-x-1/2 z-10">
                    <img className="logo mx-auto block" src={logo} alt="Logo" />
                    <p className="txt_1 font-euphoriaScript">
                      People helping people
                    </p>
                  </div>
                  <div className="middle_point absolute left-1/2 -translate-x-1/2 z-10">
                    <button onClick={connectNow} className="w-full h-full border-[3px] sm:border-[5px] border-white bg-[#211F1D] rounded-full inline-flex flex-col items-center justify-center leading-tight sm:leading-[1] font-orbitron text-white text-xl sm:text-4xl uppercase transition duration-300 hover:border-primary hover:text-primary">
                      {writeContract.modalShow ?
                        <>
                          <span className='hidden sm:block text-xs mb-2'>Validating</span>
                          <PendingModal show={writeContract.modalShow} data={writeContract.modalData} />
                        </>
                      :
                        <>
                        {!blockDiv ? 
                          <>
                            <span className="block text-xs sm:text-lg">CONNECT</span>
                            NOW
                          </>
                        :
                          <>
                            <ClipLoader
                              color='#f8c52b'
                              loading={true}
                              size={50}
                              aria-label="Loading"
                              data-testid="loader"
                              className='mx-auto'
                            />
                          </>
                        }
                        </>
                      }
                    </button>
                  </div>
                  <div className="disk_bottom  text-center absolute left-1/2 -translate-x-1/2 z-10">
                    <p className="text-white txt_shadow_one font-oxanium font-normal text-base sm:text-[24px] leading-[1.1]">
                      Dive into the future of <br />
                      Auto Arbitraging for
                    </p>
                    <strong className="text-orange block txt_shadow_one font-oxanium font-bold text-3xl sm:text-[42px] mt-2 sm:mt-3 leading-tight">
                      $18 ONLY
                    </strong>
                  </div>
                </div>
              </div>
              <LearnMore />

            </main>
            {referrerAddress && 
              <footer className="mt-auto border-[3px] sm:border-[5px] border-b-0 border-white rounded-tl-[45px] rounded-tr-[45px] bg-two shadow-one px-4 py-4 sm:py-5 pt-5 sm:pt-[24px] relative w-[calc(100vw_+_20px)] -left-[10px]">
                <p className="text-center font-bold text-white txt_shadow_one text-sm md:text-lg leading-snug sm:leading-tight">
                  Sponsored by : {referrerAddress}
                </p>
              </footer>
            }
          </div>
        </div>
      </>
    );
};

export default Connect;
