//Import CSS
import Invalidchain from "../Components/others/InvalidChain";
import { useUserAuth } from '../Contexts/authContext';
import AuthLayout from "./AuthLayout";
import DashboardLayout from "./DashboardLayout";
import LayoutLoading from "./LayoutLoading";

export default function Layout() {  
    const userAuth = useUserAuth();
    return (
        <>  
            <Invalidchain />
            {userAuth.appBootstrapped && userAuth.loggedIn === false && <AuthLayout />}
            {userAuth.appBootstrapped && userAuth.loggedIn === true && <DashboardLayout />}
            {!userAuth?.appBootstrapped && <LayoutLoading />}
        </>
    );
}