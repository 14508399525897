const runDevAsProd = false;
const settings = {
    testNet: (process.env.NODE_ENV === 'development' && !runDevAsProd)?true:false, //Change to false for production
    useDevServer: (process.env.NODE_ENV === 'development' && !runDevAsProd)?true:false //Change to false for using production servers
}

const appConfig = {
    version: '1.5.5',
    appLink: 'https://www.plan18.io/',
    websiteLink: 'https://www.plan18.io',
    testnet: settings.testNet,
    walletConnectKey: '6d3b6c3978f2d125f03bf86e6ba68425',
    customRPCURL: settings.testNet?'https://bold-misty-thunder.bsc-testnet.quiknode.pro/2e47f29249ec2044358b04e737e2438b16a753ea/':'https://attentive-black-field.bsc.quiknode.pro/c7452f3001cd9fb2edf6c525b50404c26b7b7607/', //admin@spicydevs.com
    apiEndpoint: settings.useDevServer?'https://dev.plan18.io/app/':'https://api.plan18.io/app/',
    supportedChain: settings.testNet?'97':'56',
    supportedChainName: settings.testNet?'Binance Smart Chain Testnet':'Binance Smart Chain Mainnet',
    scanURL: settings.testNet?'https://testnet.bscscan.com/':'https://bscscan.com/',
    gaCode: "G-CTDVL8W4LS",
    
    //URLs
    complan: 'https://api.plan18.io/complan.pdf',
    telegram: 'https://t.me/plan18Official',
    video: 'https://t.me/plan18Official/133',
}

export default appConfig;