import React from "react";
import Countdown from "react-countdown";

const ContractStartTime = ({ time }) => {
  return (
    <div className="flex items-center justify-end text-lg">
      <p className="text-white txt_shadow_one font-oxanium font-bold text-lg leading-tight text-center max-w-[140px] mx-auto">
        Earnings <br /> start in
        <strong className="block text-orange txt_shadow_one text-4xl leading-[.8] mt-1">
          <Countdown date={time*1000} daysInHours={true} renderer={({ hours, minutes, seconds }) => { return <span>{hours*1 > 0 && hours + ' hours'}{hours*1 == 0 && minutes*1 > 0 && minutes + ' min'}{hours*1 == 0 && minutes*1 == 0 && seconds > 0 && seconds + ' sec'}</span> }} />
        </strong>
      </p>
    </div>
  );
};

export default ContractStartTime;
