import React, { useEffect, useState } from "react";
import { formatGwei } from "viem";
import { useHelpers } from "../../../Hooks/helpers";
import { useReadContract } from "../../../Hooks/readContract";
import CircularProgressBar from "./CircularProgressBar";
import ContractEndTime from "./ContractEndTime";
import ContractStartTime from "./ContractStartTime";
import RefundContract from "./RefundContract";
import expiredImg from "../../../Assets/expired.png";

const ContractItem = ({ pack, showKey }) => {

  const readContract = useReadContract();
  const helper = useHelpers();

  const [unclaimedRewards, setUnclaimedRewards] = useState(0);

  useEffect(() => {
    getChainData();
    let interval = setInterval(() => {
      getChainData();
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const getChainData = async () => {
    if(isPackStarted(pack.expiration)) {
      const res = await readContract.read('getUnclaimedRewards', [ pack.id ]);
      setUnclaimedRewards(formatGwei(res.toString()*1));
    }
  }

  const isPackStarted = () => {
    return (new Date() / 1000 > (pack.start_time*1)); //60 days
  }

  const getPercentForPackStart = () => {
    //progress based on 24 hours and how many hours left
    let timeleft = (pack.start_time*1) - (new Date() / 1000);
    return timeleft/60/60;
  }

  const getPercentForPackEnd = () => {
    //progress based on 60 days and how many days left
    let timeleft = (pack.expiration*1) - (new Date() / 1000);
    return timeleft/60/60/24;
  }

  return (
    <>
      <div className={`rounded-[50px] border-[5px] border-white min-h-[450px] relative box-border p-6 space-y-7
       ${isPackStarted() ? 'bg-five' : 'bg-six'} 
       ${pack.refunded == 'yes' ? 'opacity-70' : ''} 
      `}>
        <h4 className="text-white text-[22px] txt_shadow_one font-orbitron font-bold text-center">
          CONTRACT #{pack.id}
        </h4>

        {isPackStarted() ?
          <>
            {pack.expiration*1 > (new Date() / 1000) ?
              <div className="flex items-center justify-center space-x-[10px]">
                <CircularProgressBar progress={getPercentForPackEnd()/60*100} size={85} strokeColor={'#40C5E6'} />
                <ContractEndTime time={pack.expiration*1} />
              </div>
            :
              <div className="flex items-center justify-center space-x-[15px]">
                <img src={expiredImg} className="w-[60px]" />
                <div className="flex items-center justify-end text-lg">
                  <p className="text-white txt_shadow_one font-oxanium font-bold text-lg leading-tight">
                    Contract
                    <strong className="block text-red-600 txt_shadow_one text-2xl leading-[.8] mt-1">
                      Expired
                    </strong>
                  </p>
                </div>
              </div>
            }
            
            <div className="flex justify-around">
              <div>
                <strong className="block text-orange text-[28px] txt_shadow_one leading-tight font-oxanium font-bold">
                  <helper.AmountToCurrency amount={unclaimedRewards} />
                </strong>
                <p className="text-white txt_shadow_one font-oxanium font-normal text-lg leading-tight">
                  Unclaimed
                </p>
              </div>
              <div>
                <strong className="block text-orange text-[28px] txt_shadow_one leading-tight font-oxanium font-bold">
                  <helper.AmountToCurrency amount={pack.totalClaimed} />
                </strong>
                <p className="text-white txt_shadow_one font-oxanium font-normal text-lg leading-tight">
                  Claimed so far
                </p>
              </div>
            </div>
          </>
        :
          <div className="relative w-[200px] h-[200px] mx-auto">
            <CircularProgressBar progress={getPercentForPackStart()/24*100} size={200} strokeColor={'#F8892B'} />
            <div className="absolute top-0 left-0 right-0 bottom-0 w-full h-full inline-flex items-center justify-center">
              <ContractStartTime time={pack.start_time*1} />
            </div>
          </div>
        }
        {pack.refunded == 'no' ? 
          <>
            {pack.expiration*1 > (new Date() / 1000) ?
              <RefundContract pack_id={pack.id} />
            :
              <button className="bg-four w-[calc(100%_+_10px)] h-32 border-[5px] border-white rounded-[50px] inline-flex flex-col items-center justify-center leading-tight sm:leading-[1] font-orbitron text-white text-xl sm:text-2xl uppercase transition duration-300 hover:border-primary hover:text-primary absolute -bottom-[5px] -left-[5px] right-0 ">
                CONTRACT
                <span className='hidden sm:block text-3xl'>Expired</span>
              </button>
            }
          </>
        :
          <button className="bg-six w-[calc(100%_+_10px)] h-32 border-[5px] border-white rounded-[50px] inline-flex flex-col items-center justify-center leading-tight sm:leading-[1] font-orbitron text-white text-xl sm:text-2xl uppercase transition duration-300 hover:border-primary hover:text-primary absolute -bottom-[5px] -left-[5px] right-0 ">
            CONTRACT
            <span className='hidden sm:block text-3xl'>REFUNDED</span>
          </button>
        }
        
      </div>
    </>
  );
};
export default ContractItem;