import { useWeb3Modal } from "@web3modal/react";
import walletBg from "../../../Assets/wallet-bg.png";
import { useWalletContext } from "../../../Contexts/walletContext";
import { useHelpers } from "../../../Hooks/helpers";
import { Link } from "react-router-dom";
import { useUserAuth } from "../../../Contexts/authContext";
import appConfig from "../../../Utility/Config/config";

const ConnectedWallet = () => {
  
  const walletContext = useWalletContext();
  const helper = useHelpers();
  const web3Modal = useWeb3Modal();
  const auth = useUserAuth();

  return (
    <div className="inline-flex items-center justify-end space-x-1 sm:space-x-3">
      <div className="font-oxanium text-white relative px-3 py-3 pb-[10px] min-w-[150px] lg:min-w-[180px]">
        <img
          className="absolute w-full h-full top-0 left-0 right-0 bottom-0"
          src={walletBg}
          alt="bg"
        />
        <p className="block txt_shadow_one font-bold text-xs_next relative z-0 leading-tight cursor-pointer" onClick={web3Modal.open}>
          {walletContext?.userAddress ? 'Connected Wallet' : 'Connect Wallet'}
        </p>
        <strong className="block txt_shadow_one text-base leading-tight font-extrabold relative z-0 cursor-pointer" onClick={web3Modal.open}>
          {walletContext?.userAddress && <span><helper.trimAddress address={walletContext?.userAddress} copy /></span>}
        </strong>
      </div>

      {auth.loggedIn &&
        <Link to={'/logout'}>
          <button className="mt-2">
            <svg id="fi_3686918" height="44" viewBox="0 0 512 512" width="44" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><circle cx="256" cy="256" fill="#f44336" r="256"></circle><path d="m275.631 229.351v-123.727a19.628 19.628 0 0 0 -39.255 0v123.727a19.628 19.628 0 0 0 39.255 0zm150.369 26.649a170 170 0 1 1 -256.023-146.644 19.86 19.86 0 0 1 29.91 17.121c0 7.71-3.014 13.922-9.753 17.907a129.854 129.854 0 0 0 -63.716 111.616c0 71.455 58.136 129.586 129.582 129.586s129.592-58.131 129.592-129.586a129.864 129.864 0 0 0 -63.716-111.616 19.411 19.411 0 0 1 -9.756-17.084c0-7.807 3.114-14.111 9.994-18.055a19.422 19.422 0 0 1 19.915.113 170.3 170.3 0 0 1 83.971 146.642z" fill="#fff" fill-rule="evenodd"></path></svg>
          </button>
        </Link>
      }
      <a href={appConfig.telegram} target="blank">
        <svg
          height={44}
          viewBox="0 0 64 64"
          width={44}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fillRule="evenodd">
            <path
              d="m48 64h-32a16.0007 16.0007 0 0 1 -16-16v-32a16.0007 16.0007 0 0 1 16-16h32a16 16 0 0 1 16 16v32a16 16 0 0 1 -16 16"
              fill="#199bdf"
            />
            <path
              d="m30 18h18a9.0006 9.0006 0 0 0 .92-17.954c-.306-.017-.609-.046-.92-.046h-32a16.0007 16.0007 0 0 0 -16 16v32a30.0007 30.0007 0 0 1 30-30"
              fill="#32b4ff"
            />
            <path d="m48 32a16 16 0 1 0 16 16v-32a16 16 0 0 1 -16 16" fill="#0082be" />
            <path
              d="m45.08 17.032a3 3 0 0 1 4.11 3.229c-1.1654 7.579-3.302 21.467-4.4362 28.839a3 3 0 0 1 -4.7447 1.959c-4.0122-2.956-10.0276-7.388-13.0817-9.639a2 2 0 0 1 -.1868-3.064c2.7985-2.643 8.3723-7.907 12.2072-11.529a.5.5 0 0 0 -.6164-.782c-4.9171 3.207-12.4477 8.118-16.1385 10.525a5.9991 5.9991 0 0 1 -4.7328.795c-2.0323-.508-4.9637-1.241-7.5724-1.893a2 2 0 0 1 -.2784-3.789c9.0812-3.751 26.853-11.091 35.4707-14.651z"
              fill="#fff"
            />
          </g>
        </svg>

      </a>
    </div>
  );
};
export default ConnectedWallet;
