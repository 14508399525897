import React from "react";
import { TitlePrimary } from "../../../Utility/Utility";
import { useHelpers } from "../../../Hooks/helpers";

const UserDownlineStats = ({ stats }) => {
  
  const helper = useHelpers();

  return (
    <>
      <div className="max-w-[1054px]  mx-auto border-[5px] border-white rounded-2xl_next bg-three shadow-one p-4 md:p-7 pb-5 mb-6">
        <div className="text-center mb-5 md:mb-7">
            <TitlePrimary>MY DOWNLINE STATS</TitlePrimary>
        </div>
        <div className="table-custom-scroller w-full">
          <table className="table-custom w-full font-oxanium">
            <thead>
              <tr>
                  <th>Level</th>
                  <th>Downlines</th>
                  <th>Earned</th>
              </tr>
            </thead>
            <tbody>
              {stats ? [...Array(10)].map((e, k) => {
                return (
                  <tr key={k}>
                      <td>Level {k+1}</td>
                      <td>{stats.downlines[k] ? stats.downlines[k].users : 0}</td>
                      <td>{stats.payments[k] ? <helper.AmountToCurrency amount={stats.payments[k].payments} decimals={2} /> : 0}</td>
                  </tr>
                )
              })
              :
              <tr>
                  <td colSpan={3} className="text-center">Refer atleast 1 user to view downline stats</td>
              </tr>
              }
             
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default UserDownlineStats;
