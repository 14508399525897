import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";

import { watchAccount, watchNetwork } from '@wagmi/core';
import { useWeb3Modal } from '@web3modal/react';
import toast from "react-hot-toast";
import { useAccount, useDisconnect, useNetwork } from 'wagmi';
import { AppDialog, useAppDialog } from "../Hooks/appDialog";
import appConfig from "../Utility/Config/config";
import { useUserAuth } from "./authContext";

// create context
const WalletContext = createContext();

// context consumer hook
const useWalletContext = () => {
    // get the context
    const context = useContext(WalletContext);

    // if `undefined`, throw an error
    if (context === undefined) {
        throw new Error("WalletContext was used outside of its Provider");
    }

    return context;
};

const WalletContextProvider = ({ children }) => {

    const authContext = useUserAuth();

    const web3Modal = useWeb3Modal();
    const wallet = useAccount();
    const disconnect = useDisconnect();
    const { chain } = useNetwork();
    const appDialog = useAppDialog();

    //Declare state vars
    const [userAddress, setUserAddress] = useState("");
    const [walletConnected, setWalletConnected] = useState(false);
    const [chainId, setChainId] = useState(null);

    //Auto connect wallet when logged in
    useEffect(() => {
        if(web3Modal && walletConnected === false) {
            connectWallet();
        }
    }, []);

    //Show popup to change wallet
    useEffect(() => {
        if(walletConnected === true && authContext.loggedIn && authContext?.userBootstrap?.userData?.address.toLowerCase() !== wallet?.address?.toLowerCase()) {
            appDialog.open({
                header: 'Registered Wallet Changed',
                description: 'Do you want to logout and login with the correct wallet?',
                backdropDismiss: true,
                buttons: [
                    {
                        text: 'Logout',
                        role: 'confirm',
                        action: () => window.open('/logout', '_self')
                    },
                    {
                        text: 'Close',
                        role: 'cancel',
                    }
                ]
            });
        }
    }, [authContext, walletConnected]);

    //Connect
    const connectWallet = async () => {
        if(wallet.isConnected) {

            watchNetwork((e) => {
                e.chain?.id !== chain?.id && window.location.reload();
            });
            
            if(chain.id != appConfig.supportedChain*1) {
                toast.error("Switch to Binance Smart Chain");
            }
            
            setChainId(chain.id);
            if(chain.id == appConfig.supportedChain*1) {

                setUserAddress(wallet.address);
                setWalletConnected(true);
                
                watchAccount((e) => {
                    if(e.address.toLowerCase() !== wallet.address.toLowerCase()) {
                        window.location.reload();
                    }
                });

            } else {
                await web3Modal.open();
            }

        } else {
            await web3Modal.open();
        }
    };
    
    const disconnectWallet = useCallback( async () => {
        await disconnect.disconnectAsync();
    }, []);

    // memoize the full context value
    const contextValue = useMemo(() => ({
        userAddress,
        walletConnected,
        chainId,
        connectWallet,
        disconnectWallet
    }), [userAddress, walletConnected, chainId, connectWallet, disconnectWallet])
  
    return (
        <>
            <WalletContext.Provider value={ contextValue }>
                {children}
            </WalletContext.Provider>
            <AppDialog isOpen={appDialog.isOpen} dismiss={appDialog.close} data={appDialog.data} />
        </>
    );
};

export { WalletContextProvider, useWalletContext };
