import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { ClipLoader } from 'react-spinners';
import Swal from "sweetalert2";
import disk from "../../../Assets/disk-1.png";
import minus from "../../../Assets/minus.png";
import plus from "../../../Assets/plus.png";
import { useUserAuth } from "../../../Contexts/authContext";
import { useWalletContext } from "../../../Contexts/walletContext";
import { useCallAPI } from "../../../Hooks/callAPI";
import { PendingModal } from "../../../Hooks/pendingModal";
import { usePollAPI } from "../../../Hooks/pollAPI";
import { useReadContract } from "../../../Hooks/readContract";
import { useWriteContract } from "../../../Hooks/writeContract";
import { formatEther } from "viem";
import { useHelpers } from "../../../Hooks/helpers";
import { useDetailsModal } from "../../../Hooks/detailsModal";
import { DetailsModal } from "../../modals/DetailsModal";
import { Button } from "@material-tailwind/react";

const PurchasePack = () => {

  const auth = useUserAuth();
  const walletContext = useWalletContext();
  const writeContract = useWriteContract();
  const readContract = useReadContract();
  const pollResults = usePollAPI();
  const callAPI = useCallAPI();
  const helper = useHelpers();
  const detailsModal = useDetailsModal();

  const [userBalance, setUserBalance] = useState(false);
  const [buyBalance, setBuyBalance] = useState(0);
  const [blockDiv, setBlockDiv] = useState('');
  const [packCount, setPackCount] = useState(1);
  const [packLimit, setPackLimit] = useState(2);
  const [purchaseCooldown, setPurchaseCooldown] = useState(0);
  const [bnbInWeiRequired, setBNBInWeiRequired] = useState(0);

  //Get user balance
  useEffect(() => {
    if(auth.appBootstrapped === true && walletContext.walletConnected === true) {
        getBalances();
    }
  }, [auth, walletContext.walletConnected]);

  const getBalances = async () => {
    setUserBalance(await readContract.getUserBalance());
    const purchaseRules = await readContract.read('getPurchaseRulesData', [ walletContext.userAddress ] );
    const buyBalanceRes = await readContract.read('viewBuyBalanceInUSD', [ walletContext.userAddress ] );
    setBuyBalance(formatEther(buyBalanceRes));
    setPurchaseCooldown(purchaseRules[0].toString()*1);
    setPackLimit(purchaseRules[1].toString()*1);
  }

  const startPurchase = async (proceed = false) => {

      try {

          const cost = await readContract.read('getRequiredCostInWei', [ walletContext.userAddress, packCount ]);

          setBNBInWeiRequired(cost);

          if(proceed !== true) {
            detailsModal.open();
            return;
          }
          
          setBlockDiv('purchaseBtn');

          if(!walletContext.walletConnected) {
              await walletContext.connectWallet();
          }
          if(walletContext.walletConnected !== true) { throw 'Failed to connect to wallet'; }
          if(walletContext.userAddress.toLowerCase() != auth.userBootstrap.userData.address.toLowerCase()) { throw 'Switch to your registered wallet address'; }

          //If Presale active allow only 1 purchase per wallet
          const launched = await readContract.read('LAUNCHED_ON');
          if(launched.toString()*1 === 0) {
            //Check if user is whitelisted
            const isWhiteListed = await readContract.read('whitelistedAddress', [ walletContext.userAddress ]);
            if(isWhiteListed) {
              //Check if 1 pack already bought. Show error
              if(auth?.userBootstrap.userTotalPacks*1 >= 1) {
                throw 'Only 1 pack allowed till Official launch'; 
              }
            } else {
              throw 'Please wait for Official Launch'; 
            }
          }

          //Limit maximium packsCount to limit set in contract
          if(packCount*1 > packLimit) {
            throw 'Cannot buy more than ' + packLimit + ' packs at once.'; 
          }

          //Get required balance for pro purchase         
          if(userBalance.int < formatEther(cost)*1) { throw 'Insufficient Balance'; }

          purchaseNow(cost);

      } catch (error) {
        console.log(error);
          toast.error(error);
          setBlockDiv('');
      }

  }

  const purchaseNow = async (cost) => {
      setBlockDiv('purchaseBtn');
      await writeContract.ContractWrite({ writeFn: "buyContracts", inputData: [ packCount.toString() ], successCallback: purchaseCB, value: cost});
      setBlockDiv('');
  }

  const purchaseCB = (hash) => {
      return new Promise(resolve => {
          (async () => {
              const apiToPoll = () => callAPI("checkChainTransaction/main/" + hash);
              const pollContinueCondition = (res) => res.data.state === "pending";
              const pollRes = await pollResults(apiToPoll, pollContinueCondition, 5000);
              resolve();
              if(pollRes.data.state == "success") {
                  //Show success popup
                  Swal.fire({
                      title: "Purchase Complete!",
                      text: "Purchase completed successfully.",
                      icon: 'success'
                  });
                  await auth.getAppBootstrap();
              } else if(pollRes.data.state == "failed") {
                  toast.error("Failed to purchase.");
              }
          })();
      });
  }

  const stepCount = (type) => {
    if(type === 'add') {
      if(packCount < packLimit) {
        setPackCount(packCount+1);
      }
    } else {
      if(packCount > 1) {
        setPackCount(packCount-1);
      }
    }
  }

  return (
    <>
      <div className="disk_wrapper type-2 mx-auto relative">
        <img className="w-full" src={disk} alt="Disk" />
        <div className="disk_top text-center absolute left-1/2 -translate-x-1/2 z-10">
          <h4 className="text-white txt_shadow_one font-orbitron font-bold">
            BUY
            <span className="block">CONTRACTS</span>
          </h4>
        </div>
        <div className="middle_point absolute left-1/2 -translate-x-1/2 z-10">
          <button className="w-full h-full border-[3px] sm:border-[5px] border-white bg-[#211F1D] rounded-full inline-flex flex-col items-center justify-center leading-tight sm:leading-[1] font-orbitron text-white text-xl xl:text-[28px] uppercase transition duration-300 hover:border-primary hover:text-primary">
            {writeContract.modalShow ?
              <>
                <span className='hidden sm:block text-xs mb-2'>Validating</span>
                <PendingModal show={writeContract.modalShow} data={writeContract.modalData} />
              </>
            :
              <>
              {!blockDiv ? 
                <div onClick={startPurchase}>
                  <span className="block text-base xl:text-2xl">BUY</span>
                  NOW
                </div>
              :
                <>
                  <ClipLoader
                    color='#F8892B'
                    loading={true}
                    size={40}
                    aria-label="Loading"
                    data-testid="loader"
                    className='mx-auto'
                  />
                </>
              }
              </>
            }
          </button>
        </div>
        <div className="disk_bottom  text-center absolute left-1/2 -translate-x-1/2 z-10">
          <p className="text-white txt_shadow_one font-oxanium font-bold text-base xl:text-[24px] leading-[1.1]">
            Buy {packCount} for
          </p>
          <strong className="text-white block txt_shadow_one font-oxanium font-bold text-3xl xl:text-[42px] leading-tight">
            ${packCount*18}
          </strong>
          <div className="incrementor absolute flex items-center justify-between">
            <button onClick={() => stepCount('sub')} className="w-[50px] h-[50px] xl:w-[70px] xl:h-[70px] rounded-full bg-[#FF6D6D] inline-flex items-center justify-center ">
              <img className="w-[24px] xl:w-[24px]" src={minus} alt="-" />
            </button>
            <button onClick={() => stepCount('add')} className="w-[50px] h-[50px] xl:w-[70px] xl:h-[70px] rounded-full bg-[#49A6FC] inline-flex items-center justify-center">
              <img
                className="translate-x-[1px] xl:translate-x-[2px] w-[24px] xl:w-[40px]"
                src={plus}
                alt="+"
              />
            </button>
          </div>
          <div className="bg-three border-[3px] border-light shadow-lg mt-4 w-[200px] mx-auto rounded-lg font-oxanium py-2">
            <p className="text-primary font-bold text-2xl"><helper.AmountToCurrency amount={buyBalance} decimals={2} /></p>
            <h3 className="text-sm text-gray-200">Available Buy Balance</h3>
          </div>
        </div>
      </div>
      <DetailsModal Component={ProceedModal} props={{ proceed: () => startPurchase(true), data: { packCount: packCount, buyBalance: buyBalance, bnbInWeiRequired: bnbInWeiRequired }, dismiss: detailsModal.close }} hook={detailsModal} />
    </>
  );
};

export default PurchasePack;

const ProceedModal = ({ proceed, data, dismiss }) => {
  const helper = useHelpers();
  return (
      <div className="py-2 font-oxanium">
          <div className="mt-4 text-center text-xl font-bold text-gray-900">Purchase Summary</div>
          <table className="w-full max-w-lg mx-auto mt-10 font-oxanium">
            <tr>
              <td className="p-3 border-b-[1px] border-gray-200 text-left text-gray-700">Contracts Purchasing</td>
              <td className="p-3 border-b-[1px] border-gray-200 text-right font-bold text-lg text-indigo-500">{data.packCount}</td>
            </tr>
            <tr>
              <td className="p-3 border-b-[1px] border-gray-200 text-left text-gray-700">Available Buy Balance</td>
              <td className="p-3 border-b-[1px] border-gray-200 text-right font-bold text-lg text-indigo-500"><helper.AmountToCurrency amount={data.buyBalance} decimals={3} /></td>
            </tr>
            <tr>
              <td className="p-3 border-b-[1px] border-gray-200 text-left text-gray-700">Remaining BNB to pay</td>
              <td className="p-3 border-b-[1px] border-gray-200 text-right font-bold text-lg text-green-500"><helper.AmountToCurrency amount={formatEther(data.bnbInWeiRequired)} prefix="" decimals={3} /> BNB</td>
            </tr>
          </table>
          <div className="mt-10 mb-10 flex justify-center">
            <Button onClick={() => { proceed(); dismiss(); }} color="indigo" variant="gradient" size="lg">Proceed to Buy</Button>
            <Button onClick={() => { dismiss(); }} color="red" size="lg" variant="outlined" className="ml-3">Cancel</Button>
          </div>
      </div>
  );
}